import React from "react";
import * as TableStyles from "../../../../themes/TableTheme";
import "./ClientKeyStyles.css";

interface AppNodeRowProps {
  keyID: string;
  clientKey: string;
  clientName: string;
  appName: string;
  created: string;
  isInactive: boolean;
  viewAppNodeFunc: (appKey: string) => void;
  notifierFunc: (message: string, success: boolean) => void;
}

function ClientKeyRow({
  keyID,
  clientKey,
  appName,
  clientName,
  created,
  isInactive,
  notifierFunc,
  viewAppNodeFunc,
}: AppNodeRowProps) {
  const activeStatus = isInactive ? "Inactive" : ("Active" as string);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(clientKey);
    notifierFunc("App Client Key Copied to clipboard!", true);
  };

  const [showKey, setShowKey] = React.useState<boolean>(false);
  const hiddenText = "########-####-####-####-############" as string;
  const viskeyCss = "fa fa-eye-slash keyClipboard antfSecondaryColor" as string;
  const nokeyCss = "fa fa-eye keyClipboard antfSecondaryColor" as string;
  const keyCss = showKey ? viskeyCss : (nokeyCss as string);

  const toggleKeyView = () => {
    setShowKey(!showKey);
  };

  return (
    <>
      <TableStyles.StyledTableRow hover tabIndex={-1}>
        <TableStyles.StyledTableCell component="th" align="center" scope="row">
          <span className="nodeIdField" onClick={() => viewAppNodeFunc(keyID)}>
            {clientName}
          </span>
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {appName}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          <span>
            {showKey ? clientKey : hiddenText}
            <i
              title="Toogle Show/Hide Key"
              onClick={() => toggleKeyView()}
              className={keyCss}
            />
            <i
              title="Copy Key to Clipboard"
              onClick={() => copyToClipboard()}
              className="fa fa-clipboard keyClipboard antfSecondaryColor"
            />
          </span>
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {created}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {activeStatus}
        </TableStyles.StyledTableCell>
      </TableStyles.StyledTableRow>
    </>
  );
}

export default ClientKeyRow;
