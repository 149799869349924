import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as TableStyles from "../../../../themes/TableTheme";

function AppUsageHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableStyles.StyledTableCell align="center">
          Unique&nbsp;Identifier
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          App&nbsp;Name
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Last&nbsp;Accessed&nbsp;(est)
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center"></TableStyles.StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default AppUsageHeader;
