import React from "react";
import * as Models from "../../../models/GlobalModels";
import { Formik, Form as FormikForm } from "formik";
import { apps } from "../../../data/IncidentDropdownDetails";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import * as DataUtils from "../../../utils/DataConversionUtils";
import { Container, Row, Col, Modal } from "react-bootstrap";
import LoadingBars from "../../loading/LoadingBars";
import "./ClientKeysModalStyles.css";
import { clientKeySchema } from "../../../validations/ClientKeySchema";
import { toast } from "react-toastify";
import useApi from "../../../api/useApi";

interface props {
  modalProps: Models.GenericModal;
  clientKeyObj?: Models.UIClientKey;
  globalErrorHandler: any;
  handleSuccessEvent: () => void;
  modalPropsEvent: React.Dispatch<React.SetStateAction<Models.GenericModal>>;
  clientKeyObjEvent: React.Dispatch<
    React.SetStateAction<Models.UIClientKey | undefined>
  >;
}

function ClientKeysModal({
  modalProps,
  globalErrorHandler,
  handleSuccessEvent,
  clientKeyObj,
  modalPropsEvent,
  clientKeyObjEvent,
}: props) {
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [deletedFlag, setDeletedFlag] = React.useState<boolean>(false);
  const axios = useApi();

  const closeModal = () => {
    clientKeyObjEvent(undefined);
    setDeletedFlag(false);
    modalPropsEvent({
      show: false,
      modalType: "new",
      modalId: "",
    });
  };

  const getModalTitle = () => {
    if (deletedFlag) {
      return "Delete App Client Key";
    }

    if (modalProps.modalType === "edit") {
      return "Edit App Client Key";
    } else if (modalProps.modalType === "view") {
      return "About This App Client Key";
    } else if (modalProps.modalType === "delete") {
      return "Delete App Client Key";
    } else {
      return "Create New App Client Key";
    }
  };

  const submitFormData = (values: Models.UIClientKey) => {
    if (
      DataUtils.IsNullOrEmpty(values?.clientName) ||
      DataUtils.IsNullOrEmpty(values?.appName)
    ) {
      toast.error("Client name and/or app name cannot be blank!");
    } else {
      setShowLoader(true);
      if (modalProps.modalType === "edit") {
        updateRecord(values);
      } else {
        //Create
        createNewRecord(values);
      }
    }
  };

  const updateRecord = (values: Models.UIClientKey) => {
    axios
      .put("/ClientManagement/UpdateClientKey", values)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Client Key Updated!");
        }
        setShowLoader(false);
        handleSuccessEvent();
      })
      .catch((error: Error) => {
        setShowLoader(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const createNewRecord = (values: Models.UIClientKey) => {
    axios
      .post("/ClientManagement/CreateNewClientKey", values)
      .then((response) => {
        if (response.status === 200) {
          toast.success("New Client Key Created!");
        }
        setShowLoader(false);
        handleSuccessEvent();
      })
      .catch((error: Error) => {
        setShowLoader(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const deleteRecord = (id: string) => {
    if (!DataUtils.IsNullOrEmpty(id)) {
      axios
        .delete(`/ClientManagement/DeleteClientKey/${id}`)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Client Key Deleted!");
          }
          setShowLoader(false);
          handleSuccessEvent();
        })
        .catch((error: Error) => {
          setShowLoader(false);
          globalErrorHandler(() => {
            throw error;
          });
        });
    }
  };

  const modalFooterContainerCss =
    modalProps.modalType === "edit" || modalProps.modalType === "view"
      ? "modalFooterBtn controlSpacingTop"
      : ("modalFooterNewBtn controlSpacingTop" as string);
  const editModalCloseCss =
    modalProps.modalType === "edit" ? "editCloseBtn" : ("" as string);
  const closeBtnCss = `floatRight ${editModalCloseCss}` as string;

  const isNotNew = (String(modalProps?.modalType) !== "new") as boolean;

  return (
    <>
      <Modal
        show={modalProps.show}
        size="lg"
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h2 className="antfColor">{getModalTitle()}</h2>
        </Modal.Header>
        {!deletedFlag ? (
          <Modal.Body>
            {!showLoader ? (
              <Formik
                enableReinitialize
                initialValues={{
                  id: clientKeyObj?.id || DataUtils.GetEmptyGuid(),
                  clientName: clientKeyObj?.clientName || "",
                  appName: clientKeyObj?.appName || "",
                  key: clientKeyObj?.key || DataUtils.GetEmptyGuid(),
                  disabled: clientKeyObj?.disabled || false,
                  notes: clientKeyObj?.notes || "",
                  created:
                    clientKeyObj?.created ||
                    DataUtils.GetCurrentDotNetDateString(),
                  createdBy: clientKeyObj?.createdBy || "",
                  modified: clientKeyObj?.modified || "",
                  modifiedBy: clientKeyObj?.modifiedBy || "",
                }}
                validationSchema={clientKeySchema}
                onSubmit={(values: Models.UIClientKey) => {
                  submitFormData(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  errors,
                  values,
                }) => {
                  return (
                    <div>
                      <FormikForm>
                        <Row className="controlSpacingTop">
                          <Col xs={6}>
                            <TextField
                              name="clientName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.clientName}
                              className="controlWidth"
                              error={
                                (errors.clientName ? true : false) &&
                                touched.clientName
                              }
                              required
                              label={
                                errors.clientName
                                  ? errors.clientName
                                  : "Client Name"
                              }
                            />
                          </Col>
                          <Col xs={6}>
                            <FormControl className="controlWidth">
                              <InputLabel required>
                                {errors.appName ? errors.appName : "App Scope"}
                              </InputLabel>
                              <Select
                                name="appName"
                                value={values.appName}
                                onChange={handleChange}
                                disabled={isNotNew}
                                error={
                                  (errors.appName ? true : false) &&
                                  touched.appName
                                }
                                onBlur={handleBlur}
                              >
                                {apps.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                        {modalProps?.modalType !== "new" ? (
                          <Row className="controlSpacingTop">
                            <Col xs={12}>
                              <TextField
                                name="key"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.key}
                                disabled={true}
                                className="controlWidth"
                                error={
                                  (errors.key ? true : false) && touched.key
                                }
                                required
                                label={errors.key ? errors.key : "Client Key"}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <></>
                        )}
                        <Row className="controlSpacingTop">
                          <Col xs={12}>
                            <TextField
                              name="notes"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.notes}
                              className="controlWidth"
                              multiline
                              rows={5}
                              error={
                                (errors.notes ? true : false) && touched.notes
                              }
                              label={errors.notes ? errors.notes : "Notes"}
                            />
                          </Col>
                        </Row>
                        {modalProps?.modalType !== "new" ? (
                          <Row className="controlSpacingTop controlSpacingBottom">
                            <Col xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="disabled"
                                    checked={values.disabled}
                                    value={values.disabled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                }
                                label="Disable Client Key"
                              />
                            </Col>
                          </Row>
                        ) : (
                          <></>
                        )}
                      </FormikForm>

                      <br />

                      <Modal.Footer className={modalFooterContainerCss}>
                        <Container fluid>
                          {isNotNew ? (
                            <div className="mr-auto">
                              <span>
                                <i
                                  title="Delete This App Node"
                                  className="fa fa-trash deleteIcon"
                                  onClick={() => setDeletedFlag(true)}
                                />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="modalFooterBtn">
                            {!isNotNew || modalProps.modalType === "edit" ? (
                              <Button
                                className="submitBtn floatRight finishBtnProps"
                                onClick={() => handleSubmit()}
                                variant="contained"
                                color="primary"
                              >
                                Finish
                              </Button>
                            ) : (
                              <></>
                            )}

                            <Button
                              onClick={() => closeModal()}
                              variant="contained"
                              color="secondary"
                              className={closeBtnCss}
                            >
                              Close
                            </Button>
                          </div>
                        </Container>
                      </Modal.Footer>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <div className="modalLoadinBarPos">
                <LoadingBars />
              </div>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body>
            <h1 className="centerText clientModalDeleteIcon antfMaterialSecondary">
              <i className="fa fa-times-circle-o" />
            </h1>
            <p className="centerText">
              Are you sure you want to delete Client Key '
              {clientKeyObj?.clientName}'
            </p>
            <p className="centerText">
              <strong>Note: </strong>This operation cannot be undone!
            </p>
            <Modal.Footer>
              <div>
                <Button
                  className="submitBtn floatRight finishBtnProps"
                  onClick={() => deleteRecord(String(clientKeyObj?.id))}
                  variant="contained"
                  color="primary"
                >
                  Delete
                </Button>

                <Button
                  onClick={() => setDeletedFlag(false)}
                  variant="contained"
                  color="secondary"
                  className={closeBtnCss}
                >
                  Go Back
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default ClientKeysModal;
