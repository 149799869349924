/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import logo from "../../assets/ANTFServiceStar.png";
import { userObject } from "../../store/UserContext";
import { useRecoilState } from "recoil";
import * as Models from "../../models/GlobalModels";
import {
  Button,
  Container,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import BellIcon from "../bellIcon/BellIcon";
import { useKeycloak } from "@react-keycloak/web";
import "./NavigationBar.css";

interface NavigationBarProps {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
  pageUrlFunc: React.Dispatch<React.SetStateAction<string>>;
}

function NavigationBar({
  globalErrorHandler,
  notifierFunc,
  pageUrlFunc,
}: NavigationBarProps) {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [userObj] = useRecoilState<Models.AntfIdentityModel>(userObject);
  const { keycloak, initialized } = useKeycloak();

  const navigateTo = (pageName: string) => {
    let page = `./${pageName}` as string;
    if (pageName === "home") {
      page = "./";
    }
    history.push(page);
  };

  const searchTicketNumber = (ticketNum: string) => {
    if (ticketNum !== undefined && ticketNum.length > 0) {
      pageUrlFunc(ticketNum);
      history.push({
        pathname: "./my-ticket",
        search: `?incident=${ticketNum}`,
      });
    } else {
      notifierFunc(
        "Field cannot be empty. Please Enter a Ticket Number",
        false
      );
    }
  };

  const searchEnterPress = (e: any, ticketNum: string) => {
    if (e.key === "Enter") {
      searchTicketNumber(ticketNum);
    }
  };

  return (
    <>
      <Navbar expand="lg" className="barColor shadow-sm p-2 mb-3">
        <Container fluid>
          <Navbar.Brand
            className="navText logoFontSize selectionCursor"
            onClick={() => navigateTo("home")}
          >
            <img
              alt=""
              src={logo}
              width="40"
              height="40"
              className="d-inline-block align-top"
            />{" "}
            ANTF ServiceStar
          </Navbar.Brand>
          <Navbar.Toggle className="navbar-dark" aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "300px" }}
              navbarScroll
            >
              <Nav.Link
                className="navText"
                onClick={() => navigateTo("new-ticket")}
              >
                Create Ticket
              </Nav.Link>
              <Nav.Link
                className="navText"
                onClick={() => navigateTo("my-tickets")}
              >
                My Tickets
              </Nav.Link>
              <div className="hideNavOption">
                <Nav.Link
                  className="navText"
                  onClick={() => navigateTo("my-messages")}
                >
                  Messages
                </Nav.Link>
              </div>
              {initialized && keycloak.authenticated && userObj.hasAdminRole ? (
                <NavDropdown
                  title={<span className="navText">Admin</span>}
                  className="navText"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item
                    onClick={() => navigateTo("ticket-explorer")}
                  >
                    Master Ticket Explorer
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigateTo("app-nodes")}>
                    App Node Management
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigateTo("client-keys")}>
                    Client Key Management
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => navigateTo("sentinal-events")}
                  >
                    View Sentinal Error Reports
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigateTo("usage-monitor")}>
                    View App Usage Reports
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <></>
              )}
            </Nav>
            <div className="hideNotifications navBadge">
              {initialized && keycloak.authenticated ? (
                <BellIcon
                  globalErrorHandler={globalErrorHandler}
                  navigateTo={navigateTo}
                />
              ) : (
                <></>
              )}
            </div>

            {initialized && keycloak.authenticated ? (
              <i
                className="fa fa-cog generalIconProps"
                title="My Settings"
                onClick={() => navigateTo("settings")}
              ></i>
            ) : (
              <></>
            )}

            {initialized && keycloak.authenticated ? (
              <>
                <div className="d-flex">
                  <FormControl
                    type="search"
                    placeholder="Search Ticket Number"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={(e) => searchEnterPress(e, searchTerm)}
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button
                    className="btn btn-light"
                    onClick={() => searchTicketNumber(searchTerm)}
                  >
                    Search
                  </Button>
                </div>
                <i
                  className="fa fa-sign-out logoutIconProps"
                  title="Logout"
                  onClick={() => keycloak.logout()}
                ></i>
              </>
            ) : (
              <i
                className="fa fa-sign-in logoutIconProps"
                title="Login"
                onClick={() => keycloak.login()}
              ></i>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
