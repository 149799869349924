import React from "react";
import { Container, Row } from "react-bootstrap";
import NoAccessImg from "../../assets/NotFoundError.png";
import "./NotFound.css";

function NotFound() {
  return (
    <>
      <Container className="notFoundDataContainer" fluid>
        <Row>
          <img
            className="notFoundImgSize notFoundImgProps"
            src={NoAccessImg}
            alt="404 Not Found"
          />
        </Row>
      </Container>
    </>
  );
}

export default NotFound;
