/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import * as Models from "../../models/GlobalModels";
import useApi from "../../api/useApi";
import "./MessageViewer.css";

interface MessageViewerProps {
  messageID: number;
  errorHandler: any;
  refreshMessages: () => void;
  notifyFunc: (message: string, success: boolean) => void;
  selMessageFunc: React.Dispatch<React.SetStateAction<number>>;
  mode: string;
}

function MessageViewer({
  messageID,
  errorHandler,
  refreshMessages,
  notifyFunc,
  selMessageFunc,
  mode,
}: MessageViewerProps) {
  const axios = useApi();

  const [selMessage, setSelMessage] = React.useState<
    Models.UIMessage | undefined
  >(undefined);

  const deleteMessage = () => {
    const request = `/Messages/DeleteMessage/${messageID}` as string;
    axios
      .delete(request)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          refreshMessages();
          notifyFunc(result.message, true);
          setSelMessage(undefined);
        }
      })
      .catch((error: Error) => {
        errorHandler(() => {
          throw error;
        });
      });
  };

  const archiveMessage = () => {
    const request = `/Messages/ArchiveMessage/${messageID}` as string;
    axios
      .put(request, messageID)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          refreshMessages();
          notifyFunc(result.message, true);
          setSelMessage(undefined);
          selMessageFunc(0);
        }
      })
      .catch((error: Error) => {
        errorHandler(() => {
          throw error;
        });
      });
  };

  const unArchiveMessage = () => {
    const request = `/Messages/UndoArchiveMessage/${messageID}` as string;
    axios
      .put(request, messageID)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          refreshMessages();
          notifyFunc(result.message, true);
          setSelMessage(undefined);
          selMessageFunc(0);
        }
      })
      .catch((error: Error) => {
        errorHandler(() => {
          throw error;
        });
      });
  };

  const markMessageAsRead = () => {
    const request = `/Messages/SetMessageAsRead/${messageID}` as string;
    axios
      .put(request, messageID)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          refreshMessages();
        }
      })
      .catch((error: Error) => {
        errorHandler(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    if (messageID > 0) {
      markMessageAsRead();
      const request = `/Messages/GetMessage/${messageID}` as string;
      axios
        .get(request)
        .then((response) => {
          const result = response.data as Models.MessageFetch;
          setSelMessage(result.message);
        })
        .catch((error: Error) => {
          errorHandler(() => {
            throw error;
          });
        });
    }
  }, [messageID]);

  return (
    <>
      {messageID > 0 && selMessage !== undefined ? (
        <Card className="shadow rounded">
          <Card.Header>{selMessage.header}</Card.Header>
          <Card.Body className="msgBodyProps">{selMessage.body}</Card.Body>
          <Card.Footer>
            <div className="mobileShow">
              <span
                className="btnLocationProps boldBtnTrash"
                onClick={() => deleteMessage()}
              >
                <i className="fa fa-trash btnIconSpacing"></i>
                Delete Message
              </span>
              {mode === "archives" ? (
                <span
                  className="btnLocationProps btnSpacing boldBtnArchive"
                  onClick={() => unArchiveMessage()}
                >
                  <i className="fa fa-archive btnIconSpacing"></i>Un-Archive
                  Message
                </span>
              ) : (
                <span
                  className="btnLocationProps btnSpacing boldBtnArchive"
                  onClick={() => archiveMessage()}
                >
                  <i className="fa fa-archive btnIconSpacing"></i>Archive
                  Message
                </span>
              )}
            </div>
            <div className="mobileHide">
              <span
                className="btnLocationProps boldBtnTrash"
                onClick={() => deleteMessage()}
              >
                <i className="fa fa-trash btnIconSpacing"></i>
              </span>
              {mode === "archives" ? (
                <span
                  className="btnLocationProps btnSpacing boldBtnArchive"
                  onClick={() => unArchiveMessage()}
                >
                  <i className="fa fa-envelope-o btnIconSpacing"></i>
                </span>
              ) : (
                <span
                  className="btnLocationProps btnSpacing boldBtnArchive"
                  onClick={() => archiveMessage()}
                >
                  <i className="fa fa-archive btnIconSpacing"></i>
                </span>
              )}
            </div>
          </Card.Footer>
        </Card>
      ) : (
        <div>
          <p className="noMsgText noMsgTextProps">
            <i className="fa fa-envelope-o"></i>
          </p>
          <p className="noMsgText">Click a message to view its contents</p>
        </div>
      )}
    </>
  );
}

export default MessageViewer;
