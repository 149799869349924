import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./CategorySummary.css";

interface CategorySummaryProps {
  category: string;
  subCat: string;
  errorCode: string;
}

function CategorySummary({
  category,
  subCat,
  errorCode,
}: CategorySummaryProps) {
  const subCatCss = subCat !== "" ? "showElement" : ("hideElement" as string);
  const colSize = subCat !== "" ? 6 : (9 as number);

  return (
    <>
      <h5 className="headerPadding antfColor">Category Information</h5>
      <Container className="catContainerProps" fluid>
        <Row>
          <Col sm={3} md={3} lg={3}>
            <p className="leftText">
              <span className="boldParagraph antfSecondaryColor">
                Category:{" "}
              </span>
              {category}
            </p>
          </Col>
          <Col className={subCatCss} sm={3} md={3} lg={3}>
            <p className="leftText">
              <span className="boldParagraph antfSecondaryColor">
                Sub Category:{" "}
              </span>
              {subCat}
            </p>
          </Col>
          <Col sm={colSize} md={colSize} lg={colSize}>
            <p className="leftText">
              <span className="boldParagraph antfSecondaryColor">
                Error Codes:{" "}
              </span>
              {errorCode}
            </p>
          </Col>
        </Row>
        <hr />
      </Container>
    </>
  );
}

export default CategorySummary;
