export const apps = [
  "",
  "ANTF Videon X",
  "ANTF Videon",
  "ANTF Cartoona",
  "ANTF ComicSense",
  "ANTF Nebula VOS",
  "ANTF Virtual Keyboard",
  "ANTF QuickNotes",
  "ANTF NoDoze",
  "Skylines Minecraft World",
  "ANTF Minecraft World",
  "ANTF Corp Site",
  "ANTF Webmail Site",
  "ANTF Gemini Site",
  "ANTF Budget Dove Site",
  "ANTF Skylines Business Site",
  "ANTF WingIT Search Site",
  "Legacy Product/Site/Other",
] as string[];

export const login = [
  "",
  "Cant Recover Login Info",
  "KeyCloak Issues",
  "Account Locked",
  "General Account Issues",
] as string[];

export const docs = [
  "",
  "Document Missing",
  "Typo or Clarification",
  "Outdated",
  "Other",
] as string[];

export const sites = [
  "",
  "Website is Down",
  "Connection Slowness",
  "Other",
] as string[];
