// Returns environment name such as 'Local', 'Test', 'Prod'
export function getEnvironmentName() {
  return process.env.REACT_APP_ENVIRONMENT_NAME as string;
}

// Returns base URL of connection string to API Backend
export function getApiBaseConnection() {
  return process.env.REACT_APP_SERVICESTAR_API_URL as string;
}

// Returns base URL of connection string to React Frontend
export function getFrontEndConnection() {
  return process.env.REACT_APP_SERVICESTAR_FRONTEND_URL as string;
}

// Returns boolean T/F to indicate if the current environment is Local
export function isLocalEnvironment() {
  let envName: string = process.env.REACT_APP_ENVIRONMENT_NAME as string;
  let isLocal: boolean = ["Local"].includes(envName);
  return isLocal;
}

export function GetUrlParameter(url: string, paramName: string): string {
  const urlParams = new URLSearchParams(url);
  return urlParams.get(paramName) as string;
}
