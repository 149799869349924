import React from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import HelpDesk from "../../assets/HelpDesk.jpg";
import "./MyHub.css";

function MyHub() {
  const history = useHistory();

  const navigateTo = (pageName: string) => {
    history.push(pageName);
  };

  return (
    <div>
      <Container fluid>
        <img
          className="center-img-block imgProps"
          src={HelpDesk}
          alt="Helpdesk"
        />
        <h3 className="centerText antfInfoColor">
          What can we help you with today?
        </h3>
        <p className="centerText">
          Welcome to ANTF ServiceStar. This is the official help and support
          portal for ANTF. Please choose an option below to get started.
        </p>
        <p className="centerText">
          For general questions or inquiries, you can reach us at
          support@antfcorp.com.
        </p>
        <div>
          <p className="centerText legalFont antfInfoColor">
            Use of ticketing services requires authentication with ANTF's
            KeyCloak user management system. If you do not have an account, you
            can register for free on the signin page.
          </p>
        </div>

        <Container fluid className="containerTest">
          <div className="optionsBuffer">
            <ButtonGroup variant="text" size="large">
              <Button
                className="buttonOptionSize"
                onClick={() => navigateTo("new-ticket")}
              >
                Create a new ticket
              </Button>
              <Button
                className="buttonOptionSize"
                onClick={() => navigateTo("my-tickets")}
              >
                View My Open Tickets
              </Button>
              <Button
                className="buttonOptionSize"
                onClick={() => navigateTo("my-messages")}
              >
                View My Messages
              </Button>
            </ButtonGroup>
          </div>
        </Container>
      </Container>
    </div>
  );
}

export default MyHub;
