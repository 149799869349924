import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import ErrorBoundary from "./errorBoundary/ErrorBoundary";
import keycloak from "./keycloak";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <ErrorBoundary>
    <RecoilRoot>
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactKeycloakProvider>
    </RecoilRoot>
  </ErrorBoundary>,
  document.getElementById("root")
);
