import React from "react";
import { Container, Row } from "react-bootstrap";
import ND from "../../assets/NoResults.png";
import "./NoDataView.css";

interface NoDataFoundProps {
  message: string;
}

function NoDataFound({ message }: NoDataFoundProps) {
  return (
    <>
      <Container className="noDataContainer" fluid>
        <Row>
          <img
            className="ndImgSize ndImgProps shadow"
            src={ND}
            alt="No Data Found"
          />
          <h3 className="noDataTextProps antfInfoColor">{message}</h3>
        </Row>
      </Container>
    </>
  );
}

export default NoDataFound;
