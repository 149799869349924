import * as Yup from "yup";

export const ticketSchema = Yup.object().shape({
  showSubCat: Yup.boolean(),
  username: Yup.string().required("Username Required"),
  email: Yup.string().email("Invalid Email").required("Email Required"),
  category: Yup.string().required("Category Required"),
  subCategory: Yup.string().when("showSubCat", {
    is: true,
    then: Yup.string().required("Sub-Category Required"),
  }),
  severity: Yup.string().required("Severity Required"),
  description: Yup.string().required("Description Required"),
  notes: Yup.string().required("Details Required"),
});
