import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as TableStyles from "../../../../themes/TableTheme";

function MuiHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableStyles.StyledTableCell align="center">
          Incident&nbsp;Number
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Description
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Category
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Opened
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Attachment
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Severity
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Status
        </TableStyles.StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default MuiHeader;
