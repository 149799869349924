import React from "react";
import { toast } from "react-toastify";
import { FormControl, Button } from "react-bootstrap";

interface SearchFieldProps {
  searchFunc: (input: string) => void;
  searchText: string;
  searchButtonText: string;
  allowNullSearch: boolean;
  searchBtnVariant?: string;
  validationExceptionKeyword?: boolean;
  disabled?: boolean;
  cssClass?: string;
}

function SearchField({
  searchFunc,
  cssClass,
  allowNullSearch,
  searchText,
  disabled,
  validationExceptionKeyword,
  searchBtnVariant,
  searchButtonText,
}: SearchFieldProps) {
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const css = `d-flex ${cssClass !== undefined ? cssClass : ""}`;

  const executeSearch = (input: string) => {
    if (allowNullSearch) {
      searchFunc(input);
    } else {
      if (
        (input === undefined || input.length === 0) &&
        !validationExceptionKeyword
      ) {
        toast.error("Sorry: Search field cannot be left blank.");
      } else {
        searchFunc(input);
      }
    }
  };

  const searchEnterPress = (
    e: React.KeyboardEvent<HTMLButtonElement>,
    input: string
  ) => {
    if (e.key === "Enter") {
      executeSearch(input);
    }
  };

  const getVariantColor = () => {
    if (searchBtnVariant === undefined || searchBtnVariant.length === 0) {
      return "secondary";
    }

    const supportedVars = [
      "primary",
      "secondary",
      "success",
      "warning",
      "danger",
      "light",
      "link",
    ] as string[];
    if (supportedVars.includes(searchBtnVariant.toLowerCase())) {
      return searchBtnVariant.toLowerCase();
    } else {
      return "secondary";
    }
  };

  return (
    <div className={css}>
      <FormControl
        type="search"
        placeholder={searchText}
        disabled={disabled}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="me-2"
        aria-label="Search"
      />
      <Button
        className="btn"
        variant={getVariantColor()}
        disabled={disabled}
        onClick={() => executeSearch(searchTerm)}
        onKeyUp={(e) => searchEnterPress(e, searchTerm)}
      >
        {searchButtonText}
      </Button>
    </div>
  );
}

export default SearchField;
