import React from "react";
import "./AntfLinkButton.css";

interface AntfLinkButtonProps {
  text: string;
  faIcon: string;
  color: string;
  classes?: string | undefined;
  onClickAction: () => void;
}

function AntfLinkButton({
  text,
  faIcon,
  color,
  classes,
  onClickAction,
}: AntfLinkButtonProps) {
  const userClasses = classes === undefined ? "" : (classes as string);
  const colorSelection =
    color === "secondary" ? "antfSecondaryText" : ("antfPrimaryText" as string);
  const pClasses = `${userClasses} ${colorSelection} antfBtnSize` as string;
  const iClasses = `fa ${faIcon} antfBtnSpacer antfIconSize` as string;

  return (
    <div className="antfBtnProps">
      <p className={pClasses} onClick={() => onClickAction()}>
        <i className={iClasses}></i>
        {text}
      </p>
    </div>
  );
}

export default AntfLinkButton;
