import React from "react";
import { Container, Row } from "react-bootstrap";
import ErrorImage from "../assets/ErrorImg.png";
import "./Error.css";

interface ErrorProps {
  statusMessage: string;
  eventID: string;
}

function Error({ statusMessage, eventID }: ErrorProps) {
  return (
    <div className="containerLoc">
      <Container className="errorDataContainer" fluid>
        <Row>
          <img
            className="errorImgSize errorImgProps"
            src={ErrorImage}
            alt="ErrorAlertImage"
          />
          <p className="centerText errorTextProps antfColor ">
            <span className="boldParagraph antfSecondaryColor">
              Sentinal Error ID:{" "}
            </span>
            {eventID}
          </p>
          <p className="centerText errorTextProps antfColor ">
            <span className="boldParagraph antfSecondaryColor">
              Error Reason:{" "}
            </span>
            {statusMessage}
          </p>
        </Row>
      </Container>
    </div>
  );
}

export default Error;
