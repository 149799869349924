import React from "react";
import NewNoteModal from "../../modals/newNoteModal/NewNoteModal";
import "./IncNote.css";

interface IncNoteProps {
  ticketNum: string;
  globalErrorHandler: any;
  ticketStatus: boolean;
  onPostSuccessFunc: () => void;
  onRefreshFunc: () => void;
}

function IncNote({
  ticketNum,
  globalErrorHandler,
  ticketStatus,
  onPostSuccessFunc,
  onRefreshFunc,
}: IncNoteProps) {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      {ticketStatus ? (
        <div className="rightText noteContainerPadding">
          <p className="noteLinkProps" onClick={() => setShowModal(true)}>
            <i
              className="fa fa-plus-square noteIcoBtn"
              title="Add a comment/notes to the ticket"
            ></i>
            Create New Note
          </p>
          <p className="noteLinkProps" onClick={() => onRefreshFunc()}>
            <i
              className="fa fa-refresh noteIcoBtn"
              title="Resync Notes for the latest notes"
            ></i>
            Refresh Notes
          </p>
          <NewNoteModal
            showFunc={showModal}
            hideFunc={setShowModal}
            ticketNum={ticketNum}
            globalErrorHandler={globalErrorHandler}
            onPostSuccessFunc={onPostSuccessFunc}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default IncNote;
