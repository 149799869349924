/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SimpleDropDown from "../../../components/simpleDropDown/SimpleDropDown";
import "./UsageMonitor.css";
import * as Models from "../../../models/GlobalModels";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import AppUsageHeader from "../../../components/tables/headers/usages/AppUsageHeader";
import AppUsageRow from "../../../components/tables/rows/appUsage/AppUsageRow";
import NoDataView from "../../../components/noDataView/NoDataView";
import LoadingBars from "../../../components/loading/LoadingBars";
import * as DataUtils from "../../../utils/DataConversionUtils";
import useApi from "../../../api/useApi";
import { TablePagination } from "@material-ui/core";
import UsageDeletionModal from "../../../components/modals/usageModals/UsageDeletionModal";

interface Props {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
}

function UsageMonitor({ globalErrorHandler, notifierFunc }: Props) {
  const [appFilter, setAppFilter] = React.useState<string>(
    DataUtils.GetEmptyGuid()
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usageData, setUsageData] = React.useState<Models.UIAppUsage[]>([]);
  const [loadingIcon, setLoadingIcon] = React.useState<boolean>(true);
  const [appList, setAppList] = React.useState<Models.DataInstance[]>([]);

  const [selectedRecord, setSelectedRecord] = React.useState<
    Models.ModalSelectionData | undefined
  >(undefined);
  const [deletionModal, setDeletionModal] = React.useState<boolean>(false);

  const axios = useApi();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const fetchSelectedApp = (appKey: string) => {
    const request = `/Usages/GetLogs/${appKey}` as string;
    axios
      .get(request)
      .then((response: { data: Models.UsageEvent }) => {
        const result = response.data as Models.UsageEvent;
        setUsageData(result?.usages);
        setLoadingIcon(false);
      })
      .catch((error: Error) => {
        setLoadingIcon(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const fetchAppList = () => {
    const request = `/Apps/GetAllAppInstances` as string;
    axios
      .get(request)
      .then((response: { data: Models.AppListEvent }) => {
        const result = response.data as Models.AppListEvent;
        setAppList(
          DataUtils.InjectAllIntoDataInstanceList("All Apps", result?.appList)
        );
      })
      .catch((error: Error) => {
        setLoadingIcon(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const openDeletionModal = (record: Models.ModalSelectionData) => {
    if (!DataUtils.IsNullOrEmpty(record?.id)) {
      setSelectedRecord(record);
      setDeletionModal(true);
    } else {
      setSelectedRecord(undefined);
      setDeletionModal(false);
    }
  };

  const onDeletionModalSuccess = () => {
    notifierFunc("Sucessfully deleted app usage record!", true);
    setLoadingIcon(true);
    setUsageData([]);
    fetchSelectedApp(appFilter);
  };

  useEffect(() => {
    fetchAppList();
  }, []);

  useEffect(() => {
    fetchSelectedApp(appFilter);
  }, [appFilter]);

  return (
    <div>
      <h3 className="nodetitleHeader">ANTF App Usage Logs</h3>
      <Container fluid>
        <Row>
          <Col lg={1} />
          <Col lg={10}>
            <div className="pageContainer shadow">
              <div className="nodeTableHeaderBuffer">
                <>
                  {!loadingIcon ? (
                    <>
                      <Row>
                        <Col lg={2}>
                          <SimpleDropDown
                            controlRenderType="standard"
                            title="Filter by application"
                            cssClass="controlWidth"
                            value={appFilter}
                            data={appList}
                            onChangeFunc={setAppFilter}
                          />
                        </Col>
                        <Col lg={10} />
                      </Row>
                      <Row className="usageTableBufer">
                        <Col lg={12}>
                          {usageData?.length > 0 ? (
                            <>
                              <UsageDeletionModal
                                showFunc={deletionModal}
                                hideFunc={setDeletionModal}
                                data={selectedRecord}
                                globalErrorHandler={globalErrorHandler}
                                onPostSuccessFunc={onDeletionModalSuccess}
                                notifierFunc={notifierFunc}
                              />
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ width: "100%", overflow: "hidden" }}
                                  stickyHeader
                                  aria-label="sticky table"
                                >
                                  <AppUsageHeader />
                                  <TableBody>
                                    {usageData
                                      .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                      .map((row) => (
                                        <AppUsageRow
                                          key={Math.random()}
                                          id={row.id}
                                          deletionAction={openDeletionModal}
                                          appName={row.appName}
                                          uniqueIdentifier={
                                            row.uniqueIdentifier
                                          }
                                          lastAccessed={row.lastAccessed}
                                        />
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={usageData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                              <br />
                              <hr />
                              <p className="centerText">
                                <i className="fa fa-info-circle iconSpacer antfMaterialPrimary" />
                                <strong>Notice:</strong> App usage records
                                display the last login for a unique user on a
                                particular application.
                              </p>
                            </>
                          ) : (
                            <>
                              <NoDataView message="No App Usage Logs Reported Yet" />
                            </>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <LoadingBars />
                    </>
                  )}
                </>
              </div>
            </div>
          </Col>
          <Col lg={1} />
        </Row>
      </Container>
    </div>
  );
}

export default UsageMonitor;
