/* eslint-disable react-hooks/exhaustive-deps */
import { Button, TextField } from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { incidentIssues, incidentSeverity } from "../../data/IncidentDropdown";
import { apps, login, sites, docs } from "../../data/IncidentDropdownDetails";
import * as Models from "../../models/GlobalModels";
import * as ImageUtilities from "../../utils/ImgUtilities";
import GenericDropdown from "../../components/genericDropdown/GenericDropdown";
import UploadControl from "../../components/uploadControl/UploadControl";
import SubmissionSuccess from "../../components/submissionSuccess/SubmissionSuccess";
import { useRecoilState } from "recoil";
import * as EnvUtils from "../../utils/envUtil";
import * as DataUtils from "../../utils/DataConversionUtils";
import { userObject } from "../../store/UserContext";
import { ticketSchema } from "../../validations/TicketSchema";
import LoadingBars from "../../components/loading/LoadingBars";
import useApi from "../../api/useApi";
import "./NewTicket.css";

interface NewTicketProps {
  notifierFunc: (message: string, success: boolean) => void;
  globalErrorHandler: any;
}

function NewTicket({ notifierFunc, globalErrorHandler }: NewTicketProps) {
  const axios = useApi();

  let eventId = EnvUtils.GetUrlParameter(window.location.search, "event");
  const isValidEventId = (eventId !== null &&
    eventId !== undefined &&
    eventId !== "") as boolean;

  const [eventData, setEventData] = React.useState<Models.TicketAutofill>(
    DataUtils.GetBlankAutoFillObject()
  );
  const [submitCompleted, setSubmitCompleted] = React.useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = React.useState<boolean>(false);
  const [ticketNum, setTicketNum] = React.useState<string>("");
  const [userObj] = useRecoilState<Models.AntfIdentityModel>(userObject);
  const [incDetails, setIncDetails] = React.useState<Models.IncidentDetails>({
    status: false,
    colNum: 6,
    data: [],
  });

  const submitFormData = (submitObject: Models.FormikTicket) => {
    const ticket = DataUtils.ConvertToTicket(submitObject) as Models.UITicket;
    const attachment = DataUtils.ConvertToAttachment(
      submitObject.imgAttachment
    ) as Models.UIAttachment;

    const submissionPayload = DataUtils.ConvertToNewIncident(
      ticket,
      attachment
    ) as Models.Incident;

    createNewIncident(submissionPayload);
  };

  const forceShowSubCategory = (visible: boolean) => {
    if (visible) {
      setIncDetails({ status: true, colNum: 4, data: apps });
    } else {
      setIncDetails({ status: false, colNum: 6, data: [] });
    }
  };

  const createNewIncident = (incident: Models.Incident) => {
    setLoadingStatus(true);
    axios
      .post("/Tickets/CreateNewIncident", incident)
      .then((response) => {
        const result = response.data as Models.TicketPost;
        if (result.success) {
          setSubmitCompleted(true);
          setTicketNum(result.incNum);
        } else {
          setSubmitCompleted(false);
        }
        notifierFunc(result.message, result.success);
        setLoadingStatus(false);
      })
      .catch((error: Error) => {
        setLoadingStatus(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const populateErrorLogDetails = (logEventId: string) => {
    if (isValidEventId) {
      const request = `SentinelEvents/GetEventByID/${logEventId}` as string;
      axios
        .get(request)
        .then((response) => {
          const result = response.data as Models.SentinalEvent;
          if (result.success) {
            const data = DataUtils.ConvertSentinelEventToAutoFill(
              result.appEvent
            ) as Models.TicketAutofill;
            setEventData(data);
            if (data.subCategory !== "") {
              forceShowSubCategory(true);
            } else {
              forceShowSubCategory(false);
            }
          }
        })
        .catch(() => {
          notifierFunc(
            "Failed to populate Sentinel Event Data. You will need to enter it manually if needed.",
            false
          );
        });
    }
  };

  useEffect(() => {
    if (isValidEventId) {
      populateErrorLogDetails(eventId);
    }
  }, [eventId]);

  return (
    <div>
      <Container fluid>
        {!loadingStatus ? (
          <Row>
            <Col className="mobilePage" sm={1} md={1} lg={2}></Col>
            <Col>
              {!submitCompleted ? (
                <Formik
                  enableReinitialize
                  initialValues={{
                    id: "",
                    showSubCat: eventData?.subCategory !== "" ? true : false,
                    username: userObj?.keyCloakIdentity?.username || "",
                    email: userObj?.keyCloakIdentity?.email || "",
                    phone: "",
                    category: eventData?.category || "",
                    subCategory: eventData?.subCategory || "",
                    severity: eventData?.severity || "",
                    errorCode: eventData?.errorMsg || "",
                    description: eventData?.description || "",
                    notes: eventData?.notes || "",
                    isAutoPopulated: isValidEventId,
                    imgAttachment: {
                      image: "",
                      title: "",
                      type: "",
                    } as Models.ImgAttachment,
                  }}
                  validationSchema={ticketSchema}
                  onSubmit={(values: Models.FormikTicket) => {
                    submitFormData(values);
                  }}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    errors,
                    values,
                  }) => {
                    const handleDetailsChange = (event: any) => {
                      const result = event.target.value as string;
                      let selection = false as boolean;

                      if (result === "Login or Security Issues") {
                        setIncDetails({ status: true, colNum: 4, data: login });
                        selection = true;
                      } else if (result === "Documentation Issues") {
                        setIncDetails({ status: true, colNum: 4, data: docs });
                        selection = true;
                      } else if (
                        result === "ANTF or CS90 Site Connection Issues"
                      ) {
                        setIncDetails({ status: true, colNum: 4, data: sites });
                        selection = true;
                      } else if (result === "Application Issues") {
                        setIncDetails({ status: true, colNum: 4, data: apps });
                        selection = true;
                      } else {
                        setIncDetails({ status: false, colNum: 6, data: [] });
                        selection = false;
                      }

                      setFieldValue("showSubCat", selection);
                      setFieldValue("category", result);
                    };

                    const handleImageUpload = async (imgData: File) => {
                      const convertedImg =
                        (await ImageUtilities.checkFileBase64(
                          imgData
                        )) as string;
                      const fileName = imgData.name as string;
                      const newImgAttachment = {
                        image: convertedImg,
                        title: fileName,
                        type: "",
                      } as Models.ImgAttachment;
                      setFieldValue("imgAttachment", newImgAttachment);
                    };

                    const cancelFileUpload = () => {
                      const defaultImg = {
                        image: "",
                        title: "",
                        type: "",
                      } as Models.ImgAttachment;
                      setFieldValue("imgAttachment", defaultImg);
                    };

                    return (
                      <div className="pageContainer shadow">
                        <FormikForm>
                          <h3 className="centerText titleBuffer antfColor">
                            Create New Helpdesk Ticket
                          </h3>
                          <h5 className="subTitlePos antfColor">
                            Contact Information
                          </h5>
                          <Row>
                            <Col sm={12} lg={4}>
                              <TextField
                                name="username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                                variant="standard"
                                className="controlWidth"
                                required
                                disabled={values.username !== ""}
                                error={
                                  (errors.username ? true : false) &&
                                  touched.username
                                }
                                label={
                                  errors.username ? errors.username : "Name"
                                }
                              />
                            </Col>
                            <Col sm={12} lg={4}>
                              <TextField
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                variant="standard"
                                className="controlWidth"
                                required
                                disabled={values.email !== ""}
                                error={
                                  (errors.email ? true : false) && touched.email
                                }
                                label={
                                  errors.email ? errors.email : "Email Address"
                                }
                              />
                            </Col>
                            <Col sm={12} lg={4}>
                              <TextField
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                variant="standard"
                                className="controlWidth"
                                label="Phone (Optional)"
                              />
                            </Col>
                          </Row>
                          <h5 className="subTitlePos headerSpacingTop antfColor">
                            Incident Information
                          </h5>
                          <Row>
                            <Col sm={12} lg={12} className="colBottomSpacing">
                              <TextField
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                variant="standard"
                                className="controlWidth"
                                required
                                error={
                                  (errors.description ? true : false) &&
                                  touched.description
                                }
                                label={
                                  errors.description
                                    ? errors.description
                                    : "Description/Subject"
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} lg={incDetails.colNum}>
                              <GenericDropdown
                                controlRenderType="standard"
                                controlName="category"
                                controlTitle="Category"
                                selValue={values.category}
                                classes="controlWidth"
                                formikChange={handleDetailsChange}
                                formikBlur={handleBlur}
                                isRequired={true}
                                isDisabled={false}
                                data={incidentIssues}
                                errorProps={errors.category}
                                touchedProps={touched.category}
                                autoPickFirst={false}
                              />
                            </Col>
                            {incDetails.status ? (
                              <Col sm={12} lg={incDetails.colNum}>
                                <GenericDropdown
                                  controlRenderType="standard"
                                  controlName="subCategory"
                                  controlTitle="Sub Category"
                                  selValue={values.subCategory}
                                  classes="controlWidth"
                                  formikChange={handleChange}
                                  formikBlur={handleBlur}
                                  isRequired={true}
                                  isDisabled={false}
                                  data={incDetails.data}
                                  errorProps={errors.subCategory}
                                  touchedProps={touched.subCategory}
                                  autoPickFirst={false}
                                />
                              </Col>
                            ) : (
                              <></>
                            )}
                            <Col sm={12} lg={incDetails.colNum}>
                              <GenericDropdown
                                controlRenderType="standard"
                                controlName="severity"
                                controlTitle="Serverity/Impact"
                                selValue={values.severity}
                                classes="controlWidth"
                                formikChange={handleChange}
                                formikBlur={handleBlur}
                                isRequired={true}
                                isDisabled={false}
                                data={incidentSeverity}
                                errorProps={errors.severity}
                                touchedProps={touched.severity}
                                autoPickFirst={false}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} lg={12} className="colTopSpacingSM">
                              <TextField
                                name="errorCode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.errorCode}
                                variant="standard"
                                className="controlWidth"
                                label="Error Code or Message?"
                              />
                            </Col>
                          </Row>
                          <h5 className="subTitlePos headerSpacingTop antfColor">
                            Additional Information
                          </h5>
                          <Row className="titleBuffer">
                            <Col>
                              <TextField
                                name="notes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.notes}
                                className="controlWidth"
                                multiline
                                rows={10}
                                required
                                error={
                                  (errors.notes ? true : false) && touched.notes
                                }
                                label={
                                  errors.notes
                                    ? errors.notes
                                    : "Describe the issue/Notes"
                                }
                              />
                            </Col>
                          </Row>
                          <hr />
                          <UploadControl
                            imageReturnFunc={handleImageUpload}
                            notifier={notifierFunc}
                            selectedFilename={values.imgAttachment.title}
                            cancelUploadFunc={cancelFileUpload}
                          />
                        </FormikForm>
                        <div className="colTopSpacing">
                          <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            onClick={() => handleSubmit()}
                          >
                            Submit Ticket
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              ) : (
                <div className="pageContainer shadow">
                  <SubmissionSuccess
                    notifierFunc={notifierFunc}
                    refNum={ticketNum}
                  />
                </div>
              )}
            </Col>
            <Col className="mobilePage" sm={1} md={1} lg={2}></Col>
          </Row>
        ) : (
          <LoadingBars />
        )}
      </Container>
    </div>
  );
}

export default NewTicket;
