/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import useApi from "../../../api/useApi";
import Switch from "@mui/material/Switch";
import { useRecoilState } from "recoil";
import { userObject } from "../../../store/UserContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import * as Models from "../../../models/GlobalModels";
import "./ComSettings.css";

interface ComSettingsProps {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
}

function ComSettings({ globalErrorHandler, notifierFunc }: ComSettingsProps) {
  const axios = useApi();
  const [userSettings, setUserSettings] = React.useState<
    Models.UICommunication | undefined
  >(undefined);

  const [userObj] = useRecoilState<Models.AntfIdentityModel>(userObject);

  const submitFormData = (values: Models.UICommunication) => {
    axios
      .post("/Mail/SaveCommunicationPreferences", values)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          notifierFunc("Communication preferences successfully updated", true);
        }
      })
      .catch((error) => {
        if (error.message.includes("400")) {
          notifierFunc(error.response.data.message, false);
        } else {
          globalErrorHandler(() => {
            throw error;
          });
        }
      });
  };

  useEffect(() => {
    axios
      .get("/Mail/GetCommunicationPreference")
      .then((response) => {
        const result = response.data as Models.CommunicationRecord;
        if (result.success) {
          setUserSettings(result.commObject);
        }
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  }, []);

  return (
    <Container>
      <h4 className="antfColor titleSpacer">
        <i className="fa fa-envelope-o settingsTitleIconSpacer" />
        Communication Preferences
      </h4>
      <>
        {userSettings !== undefined ? (
          <>
            <p className="antfSecondaryColor">
              This affects bell icon notifications and external email
              communications to: {userObj.keyCloakIdentity?.email}
            </p>
            <Formik
              enableReinitialize
              initialValues={{
                id: userSettings?.id || "",
                emailAddress: userObj.keyCloakIdentity?.email || "",
                totalUnsuscribe: userSettings?.totalUnsuscribe || false,
                essentialNotificationsOnly:
                  userSettings?.essentialNotificationsOnly || false,
                receiveInternalAppCommunications:
                  userSettings?.receiveInternalAppCommunications || false,
                created: userSettings?.created || "",
                modified: userSettings?.modified || "",
              }}
              onSubmit={(values: Models.UICommunication) => {
                submitFormData(values);
              }}
            >
              {({
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                values,
              }) => {
                const handleTotalUnsuscribe = (val: boolean) => {
                  if (val) {
                    setFieldValue("essentialNotificationsOnly", false);
                    setFieldValue("receiveInternalAppCommunications", false);
                  }
                  setFieldValue("totalUnsuscribe", val);
                };

                return (
                  <div>
                    <FormikForm>
                      <Row>
                        <FormControlLabel
                          control={
                            <Switch
                              name="essentialNotificationsOnly"
                              onBlur={handleBlur}
                              disabled={values.totalUnsuscribe}
                              checked={values.essentialNotificationsOnly}
                              onChange={handleChange}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Essential External Notifications Only (Outgoing External Emails)"
                          className="antfSecondaryColor"
                        />
                      </Row>
                      <Row>
                        <FormControlLabel
                          control={
                            <Switch
                              name="receiveInternalAppCommunications"
                              onBlur={handleBlur}
                              disabled={values.totalUnsuscribe}
                              checked={values.receiveInternalAppCommunications}
                              onChange={handleChange}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Receive Internal App Communications (Outgoing Bell Notifications)"
                          className="antfSecondaryColor"
                        />
                      </Row>
                      <Row>
                        <br />
                        <hr />
                        <p className="antfErrorColor">
                          This option stops all outgoing bell notifications and
                          external email notifications (Not Recommended)
                        </p>
                        <FormControlLabel
                          control={
                            <Switch
                              name="totalUnsuscribe"
                              onBlur={handleBlur}
                              checked={values.totalUnsuscribe}
                              onChange={(e) =>
                                handleTotalUnsuscribe(e.target.checked)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Total Unsuscribe"
                          className="antfErrorColor"
                        />
                      </Row>
                    </FormikForm>

                    <br />

                    <Button
                      className="submitBtn"
                      onClick={() => handleSubmit()}
                      variant="contained"
                      color="secondary"
                    >
                      Update Communication Preferences
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <p className="antfSecondaryColor">
              Loading Communication Settings...
            </p>
          </>
        )}
      </>
    </Container>
  );
}

export default ComSettings;
