import { atom } from "recoil";
import * as Models from "../models/GlobalModels";

export const userObject = atom({
  key: "userObject",
  default: {
    keyCloakIdentity: undefined,
    hasAdminRole: false,
  } as Models.AntfIdentityModel,
});
