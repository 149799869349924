/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useApi from "../../api/useApi";
import LoadingBars from "../../components/loading/LoadingBars";
import NotificationBubble from "../../components/notificationBubble/NotificationBubble";
import MessageViewer from "../../components/messageViewer/MessageViewer";
import * as Models from "../../models/GlobalModels";
import "./Messages.css";

interface MyNotificationProps {
  notifierFunc: (message: string, success: boolean) => void;
  globalErrorHandler: any;
}

function Messages({ notifierFunc, globalErrorHandler }: MyNotificationProps) {
  const [mail, setMail] = React.useState<Models.Mailbox | undefined>(undefined);
  const [value, setValue] = React.useState<string>("messages");
  const [selectedMessage, setSelectedMessage] = React.useState<number>(0);
  const axios = useApi();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const msgCount = (mail !== undefined ? mail.count : 0) as number;

  const getMessages = (msgType: string) => {
    let request = "/Messages/GetAllInboxMessages" as string;
    if (msgType === "archive") {
      request = "/Messages/GetAllArchivedMessages";
    }

    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.Mailbox;
        setMail(result);
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const fetchMessages = () => {
    if (value === "archives") {
      getMessages("archive");
    } else {
      getMessages("notification");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [value]);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="mobilePage" sm={1} md={1} lg={1}></Col>
          <Col>
            <div className="pageContainer shadow">
              <Row>
                <Col sm={6} md={6} lg={6}>
                  <>
                    <Box sx={{ width: "100%" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                      >
                        <Tab value="messages" label="Notifications" />
                        <Tab value="archives" label="Archive" />
                      </Tabs>
                    </Box>
                    <div className="messageContainer">
                      <div className="scollableContent">
                        <>
                          {mail !== undefined ? (
                            <>
                              {mail?.count > 0 ? (
                                <>
                                  {mail.messageList.map((msg) => (
                                    <NotificationBubble
                                      key={Math.random()}
                                      itemKey={msg.id}
                                      icon={msg.messageIcon}
                                      subject={msg.header}
                                      notificationType={msg.messageType}
                                      datestamp={msg.created}
                                      messageRead={msg.messageRead}
                                      clickHandler={setSelectedMessage}
                                    />
                                  ))}
                                </>
                              ) : (
                                <div className="noMailProps">
                                  {value !== "archives" ? (
                                    <>
                                      <h5 className="centerText inactiveGray">
                                        You have no notifications
                                      </h5>
                                      <p className="centerText noMsgIcon">
                                        <i className="fa fa-envelope-o"></i>
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <h5 className="centerText inactiveGray">
                                        You have no archived messages
                                      </h5>
                                      <p className="centerText noMsgIcon">
                                        <i className="fa fa-archive"></i>
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <LoadingBars />
                          )}
                        </>
                      </div>
                    </div>
                    <hr />
                    <p className="counterText countText">
                      <span className="countFont">Message Count:</span>{" "}
                      {msgCount}
                    </p>
                  </>
                </Col>
                <Col sm={6} md={6} lg={6}>
                  <div className="viewerProps">
                    <MessageViewer
                      messageID={selectedMessage}
                      errorHandler={globalErrorHandler}
                      refreshMessages={fetchMessages}
                      notifyFunc={notifierFunc}
                      mode={value}
                      selMessageFunc={setSelectedMessage}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="mobilePage" sm={1} md={1} lg={1}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Messages;
