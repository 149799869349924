/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import * as EnvUtils from "../../utils/envUtil";
import * as Models from "../../models/GlobalModels";
import useApi from "../../api/useApi";
import { useHistory } from "react-router-dom";
import LoadingBars from "../../components/loading/LoadingBars";
import IncSummary from "../../components/ticketComponents/incSummary/IncSummary";
import CategorySummary from "../../components/ticketComponents/categorySummary/CategorySummary";
import IncDetails from "../../components/ticketComponents/incDetails/IncDetails";
import IncNote from "../../components/ticketComponents/incNote/IncNote";
import { userObject } from "../../store/UserContext";
import { useRecoilState } from "recoil";
import IncResolution from "../../components/ticketComponents/incResolution/IncResolution";
import WorkNote from "../../components/ticketComponents/workNote/WorkNote";
import "./MyTicket.css";
import NoDataFound from "../../components/noDataView/NoDataFound";

interface MyTicketProps {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
  pageUrl: string;
}

function MyTicket({
  globalErrorHandler,
  notifierFunc,
  pageUrl,
}: MyTicketProps) {
  const axios = useApi();
  const history = useHistory();
  let incidentID = EnvUtils.GetUrlParameter(window.location.search, "incident");

  const [userObj] = useRecoilState<Models.AntfIdentityModel>(userObject);

  const [selectedIncident, setSelectedIncident] = React.useState<
    Models.Incident | undefined
  >(undefined);

  const [workNotes, setWorkNotes] = React.useState<Models.UIWorkNote[]>([]);
  const [validTicket, setValidTicket] = React.useState<boolean>(true);
  const [notesLoading, setNotesLoading] = React.useState<boolean>(true);
  const [loadingTrigger, setLoadingTrigger] = React.useState<boolean>(false);

  const closeTicket = (notes: string) => {
    setLoadingTrigger(true);
    const payload = selectedIncident?.ticket as Models.UITicket;
    payload.resolutionNotes = notes;
    console.log("Closing Payload: ", payload);
    axios
      .put("/Tickets/CloseIncident", payload)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          notifierFunc("The incident was successfully closed!", true);
          history.push("./my-tickets");
        }
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const fetchTicket = () => {
    const request = `/Tickets/GetIncidentByID/${incidentID}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.IncidentFetch;
        if (result.success) {
          setSelectedIncident(result.incidentModel);
          refreshTicketsNotes(false);
          setLoadingTrigger(false);
        }
      })
      .catch((error) => {
        if (error.message.includes("400")) {
          setValidTicket(false);
          setLoadingTrigger(false);
          notifierFunc(error.response.data.message, false);
        } else {
          globalErrorHandler(() => {
            throw error;
          });
        }
      });
  };

  const onNewNotePostSuccess = () => {
    setNotesLoading(true);
    notifierFunc("Note created successfully. Notes refreshed!", true);
    refreshTicketsNotes(false);
  };

  const refreshTicketsNotes = (showRefreshToast: boolean) => {
    const request = `/Notes/GetNotesByTicketID/${incidentID}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.NotesFetch;
        setWorkNotes(result.workNotes);
        if (showRefreshToast) {
          notifierFunc("Notes Refreshed!", true);
        }
        setNotesLoading(false);
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    if (incidentID !== null && incidentID !== "") {
      setLoadingTrigger(true);
      fetchTicket();
    }
  }, [pageUrl]);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="mobilePage" sm={1} md={1} lg={1}></Col>
          <Col>
            <div className="pageContainer ticketPageProps shadow">
              {incidentID !== null && incidentID !== "" && validTicket ? (
                <>
                  {selectedIncident !== undefined && !loadingTrigger ? (
                    <>
                      <IncSummary
                        id={incidentID}
                        user={selectedIncident.ticket.username}
                        email={selectedIncident.ticket.email}
                        phone={selectedIncident.ticket.phone}
                        createdDate={selectedIncident.ticket.created}
                        severity={selectedIncident.ticket.severity}
                        status={selectedIncident.ticket.activeTicket}
                        attachment={selectedIncident.screenshot}
                        notifierFunc={notifierFunc}
                      />
                      <CategorySummary
                        category={selectedIncident.ticket.category}
                        subCat={selectedIncident.ticket.subCategory}
                        errorCode={selectedIncident.ticket.errorMsg}
                      />
                      <IncDetails
                        desc={selectedIncident.ticket.description}
                        notes={selectedIncident.ticket.notes}
                      />
                      <IncNote
                        ticketNum={selectedIncident.ticket.id}
                        globalErrorHandler={globalErrorHandler}
                        ticketStatus={selectedIncident.ticket.activeTicket}
                        onRefreshFunc={() => refreshTicketsNotes(true)}
                        onPostSuccessFunc={onNewNotePostSuccess}
                      />
                      {workNotes.length > 0 ? (
                        <>
                          <h5 className="centerText ticketNotesHeader antfColor">
                            Incident WorkNotes
                          </h5>
                          <Row>
                            <Col sm={2} md={2} lg={2}></Col>
                            <Col sm={8} md={8} lg={8}>
                              {!notesLoading ? (
                                <div className="workNotesContainer">
                                  <div className="notesContainerScrollableContent">
                                    {workNotes.map((note) => (
                                      <WorkNote
                                        key={Math.random()}
                                        username={
                                          note.createdBy ===
                                          userObj?.keyCloakIdentity?.username
                                            ? "You"
                                            : note.createdBy
                                        }
                                        noteDate={note.created}
                                        noteData={note.note}
                                      />
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <LoadingBars />
                              )}
                            </Col>
                            <Col sm={2} md={2} lg={2}></Col>
                          </Row>
                          <hr />
                        </>
                      ) : (
                        <>
                          <h5 className="centerText ticketNotesHeader antfColor">
                            Incident WorkNotes
                          </h5>
                          <p className="noTicketTextProps">
                            <i className="fa fa-commenting-o noTicketTextPropsIcon"></i>
                            There are no worknotes on this ticket yet.
                          </p>
                        </>
                      )}

                      {userObj.hasAdminRole ||
                      !selectedIncident.ticket.activeTicket ||
                      userObj.keyCloakIdentity?.username ===
                        selectedIncident.ticket.username ? (
                        <IncResolution
                          closedTime={selectedIncident.ticket.closed}
                          resNotes={selectedIncident.ticket.resolutionNotes}
                          closeIncFunc={closeTicket}
                        />
                      ) : (
                        <>
                          <br />
                          <p className="antfErrorColor centerText">
                            <strong>Notice: </strong>You Cannot Close a ticket
                            that is not your own and/or you are not an
                            administrator.
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <LoadingBars />
                  )}
                </>
              ) : (
                <NoDataFound message="No Ticket ID given Or Ticket does not Exist" />
              )}
            </div>
          </Col>
          <Col className="mobilePage" sm={1} md={1} lg={1}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default MyTicket;
