import React from "react";
import { Container } from "react-bootstrap";
import * as Models from "../../../models/GlobalModels";
import * as CssUtils from "../../../utils/CssUtils";
import * as ImgUtils from "../../../utils/ImgUtilities";
import "./IncSummary.css";

interface IncSummaryProps {
  id: string;
  user: string;
  email: string;
  phone: string;
  createdDate: string;
  severity: string;
  status: boolean;
  attachment: Models.UIAttachment;
  notifierFunc: (message: string, success: boolean) => void;
}

function IncSummary({
  id,
  user,
  email,
  phone,
  createdDate,
  severity,
  status,
  attachment,
  notifierFunc,
}: IncSummaryProps) {
  const ticketStatus = status ? "Open" : ("Closed" as string);
  const attachmentText =
    attachment !== null ? attachment.imageName : ("None" as string);
  const attachmentCss =
    attachment !== null ? "attachmentTextProps" : ("" as string);
  const severityCss = CssUtils.GetTicketSeverityColor(severity) as string;

  const downloadAttachment = () => {
    if (attachment !== null) {
      ImgUtils.downloadFile(
        attachment.imageName,
        attachment.imageData,
        attachment.type
      );
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(id);
    notifierFunc("Ticket number copied to clipboard!", true);
  };

  return (
    <>
      <h5 className="headerPadding antfColor">Contact Summary</h5>
      <Container className="summarySectionProps" fluid>
        <div>
          <div className="leftSection">
            <p>
              <span className="boldParagraph antfSecondaryColor">
                Incident ID:{" "}
              </span>
              {id}
              <span>
                <i
                  title="Copy to clipboard"
                  className="fa fa-clipboard copyBtn"
                  onClick={() => copyToClipboard()}
                ></i>
              </span>
            </p>
            <p>
              <span className="boldParagraph antfSecondaryColor">
                Username:{" "}
              </span>
              {user}
            </p>
            <p>
              <span className="boldParagraph antfSecondaryColor">Email: </span>
              {email}
            </p>
            <p>
              <span className="boldParagraph antfSecondaryColor">Phone: </span>
              {phone}
            </p>
          </div>
          <div className="rightSection">
            <p>
              <span className="boldParagraph antfSecondaryColor">
                Ticket Created:{" "}
              </span>
              {createdDate}
            </p>
            <p>
              <span className="boldParagraph antfSecondaryColor">
                Severity/Impact:{" "}
              </span>
              <span className={severityCss}>{severity}</span>
            </p>
            <p>
              <span className="boldParagraph antfSecondaryColor">Status: </span>
              {ticketStatus}
            </p>
            <p>
              <span className="boldParagraph antfSecondaryColor">
                Screenshot:{" "}
              </span>
              <span
                className={attachmentCss}
                onClick={() => downloadAttachment()}
              >
                {attachment !== null ? (
                  <i className="fa fa-paperclip paperclipSpacing "></i>
                ) : (
                  <></>
                )}
                {attachmentText}
              </span>
            </p>
          </div>
        </div>
      </Container>
      <hr />
    </>
  );
}

export default IncSummary;
