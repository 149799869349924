/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import useApi from "../../../api/useApi";
import * as Models from "../../../models/GlobalModels";
import LoadingBars from "../../../components/loading/LoadingBars";
import SimpleDropDown from "../../../components/simpleDropDown/SimpleDropDown";
import NoDataView from "../../../components/noDataView/NoDataView";
import EventLogsHeader from "../../../components/tables/headers/myEventLogs/EventLogsHeader";
import EventLogRow from "../../../components/tables/rows/eventLogs/EventLogRow";
import { Paper, Table, TableBody, TableContainer } from "@mui/material/";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";
import { TablePagination } from "@material-ui/core";
import "./SentinalLogs.css";
import EventLogViewer from "../../../components/eventComponents/eventLogViewer/EventLogViewer";
import SearchField from "../../../components/searchField/SearchField";

interface SentinalLogsProps {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
}

function SentinalLogs({ globalErrorHandler, notifierFunc }: SentinalLogsProps) {
  const axios = useApi();
  const [eventNodeApps, setEventNodeApps] = React.useState<
    Models.DataInstance[]
  >([]);
  const [eventErrorLogs, setEventErrorLogs] = React.useState<
    Models.UISentinelEvent[]
  >([]);

  const [selectedApp, setSelectedApp] = React.useState<string>("");
  const [loadingIcon, setLoadingIcon] = React.useState<boolean>(true);
  const [selectedEvent, setSelectedEvent] = React.useState<
    Models.UISentinelEvent | undefined
  >(undefined);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [inactiveEvents, setInactiveEvents] = React.useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectedErrorLog = (eventLogID: string) => {
    fetchSelectedApp(eventLogID);
  };

  const fetchSelectedApp = (eventID: string) => {
    const request = `/SentinelEvents/GetEventByID/${eventID}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.SentinalEvent;
        setSelectedEvent(result?.appEvent);
        setLoadingIcon(false);
      })
      .catch((error: Error) => {
        setLoadingIcon(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const fetchAllActiveEventsByApp = (appID: string, inactive: boolean) => {
    const request =
      `/SentinelEvents/GetEventLogsByAppNode/${appID}/${inactive}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.SentinalEventList;
        if (result.appEvents.length === 0) {
          setSelectedEvent(undefined);
        }
        setEventErrorLogs(result.appEvents);
        setLoadingIcon(false);
      })
      .catch((error: Error) => {
        setLoadingIcon(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const handleEventActivity = (eventDat: boolean) => {
    setInactiveEvents(eventDat);
    fetchEventActivity(eventDat);
    setSelectedEvent(undefined);
  };

  const toggleEventResolved = (eventId: string, resolved: boolean) => {
    const url = resolved
      ? `/SentinelEvents/MarkEventAsResolved/${eventId}`
      : (`/SentinelEvents/ReOpenEvent/${eventId}` as string);
    console.log("EventUrl: ", url);
    axios
      .put(url, eventId)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          notifierFunc(result.message, result.success);
          fetchEventActivity(inactiveEvents);
          setSelectedEvent(undefined);
        }
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const fetchAllActiveEvents = () => {
    axios
      .get("/SentinelEvents/GetEventLogs")
      .then((response) => {
        const result = response.data as Models.SentinalSummary;
        setEventNodeApps(result.summaryData.appNodes);
        setEventErrorLogs(result.summaryData.appEvents);
        setLoadingIcon(false);
      })
      .catch((error: Error) => {
        setLoadingIcon(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const fetchEventActivity = (resolved: boolean) => {
    const url = `/SentinelEvents/GetEventLogActivity/${resolved}` as string;
    axios
      .get(url)
      .then((response) => {
        const result = response.data as Models.SentinalEventList;
        setEventErrorLogs(result.appEvents);
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const searchRequest = (input: string) => {
    const request = `/SentinelEvents/SearchErrorLogs/${input}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.SentinalEventList;
        if (result.success) {
          setSelectedEvent(undefined);
          setEventErrorLogs(result.appEvents);
          if (result.appEvents.length === 0) {
            notifierFunc("No results were found", false);
          } else {
            notifierFunc(`${result.appEvents.length} result(s) found!`, true);
          }
        }
      })
      .catch((error) => {
        if (error.message.includes("400")) {
          notifierFunc(error.response.data.message, false);
        } else {
          globalErrorHandler(() => {
            throw error;
          });
        }
      });
  };

  useEffect(() => {
    fetchAllActiveEvents();
  }, []);

  useEffect(() => {
    if (selectedApp !== "") {
      setLoadingIcon(true);
      setInactiveEvents(false);
      fetchAllActiveEventsByApp(selectedApp, false);
    }
  }, [selectedApp]);

  return (
    <div>
      <h3 className="nodetitleHeader">ANTF Sentinal Event Logs</h3>
      <Container fluid>
        <Row>
          <Col lg={1}></Col>
          <Col lg={10}>
            <div className="pageContainer shadow">
              <div className="nodeTableHeaderBuffer">
                {!loadingIcon ? (
                  <>
                    <Row>
                      <Col lg={4}>
                        <FormControlLabel
                          className="inactiveTicketsLoc"
                          control={
                            <Checkbox
                              checked={inactiveEvents}
                              onChange={(e) =>
                                handleEventActivity(e.target.checked)
                              }
                            />
                          }
                          label="Show Closed/Resolved Tickets"
                        />
                      </Col>
                      <Col lg={4}>
                        <SearchField
                          searchButtonText="Search"
                          searchText="Global Search Error ID or Descriptor"
                          searchFunc={searchRequest}
                          allowNullSearch={false}
                          cssClass="searchField"
                        />
                      </Col>
                      <Col lg={2}></Col>
                      <Col lg={2}>
                        <SimpleDropDown
                          controlRenderType="standard"
                          title="Filter By App"
                          cssClass="controlWidth"
                          value={selectedApp}
                          data={eventNodeApps}
                          onChangeFunc={setSelectedApp}
                        />
                      </Col>
                    </Row>
                    <Row className="errorTableBufer">
                      {eventErrorLogs.length > 0 ? (
                        <>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ width: "100%", overflow: "hidden" }}
                              stickyHeader
                              aria-label="sticky table"
                            >
                              <EventLogsHeader />
                              <TableBody>
                                {eventErrorLogs
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row) => (
                                    <EventLogRow
                                      key={Math.random()}
                                      eventId={row.eventID}
                                      errorMsg={row.errorMessage}
                                      appName={row.applicationName}
                                      framework={row.platform}
                                      created={row.created}
                                      status={row.isResolved}
                                      eventSelectionFunc={
                                        handleSelectedErrorLog
                                      }
                                    />
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={eventErrorLogs.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                          {selectedEvent !== undefined ? (
                            <EventLogViewer
                              eventLog={selectedEvent}
                              toggleResolvedFunc={toggleEventResolved}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <NoDataView message="No Event Logs Created Yet" />
                        </>
                      )}
                    </Row>
                  </>
                ) : (
                  <LoadingBars />
                )}
              </div>
            </div>
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default SentinalLogs;
