/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as Models from "../../../models/GlobalModels";
import AppNodeHeader from "../../../components/tables/headers/appNodes/AppNodes";
import AppNodeRow from "../../../components/tables/rows/appNodes/AppNodeRow";
import AntfLinkButton from "../../../components/buttons/antfLinkButton/AntfLinkButton";
import NoDataView from "../../../components/noDataView/NoDataView";
import LoadingBars from "../../../components/loading/LoadingBars";
import useApi from "../../../api/useApi";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import AppNodeModal from "../../../components/modals/appNodeModal/AppNodeModal";
import "./AppNodeManager.css";

interface AppNodeManagerProps {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
}

function AppNodeManager({
  globalErrorHandler,
  notifierFunc,
}: AppNodeManagerProps) {
  const axios = useApi();
  const [loader, setLoader] = React.useState<boolean>(true);
  const [nodes, setNodes] = React.useState<Models.UIAppNode[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const [modalProps, setModalProps] = React.useState<Models.AppNodeModal>({
    selId: 0,
    modalType: "new",
    modalId: 0,
  });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const createNewNode = () => {
    setModalProps({
      selId: 0,
      modalType: "new",
      modalId: Math.random(),
    });
    setShowModal(true);
  };

  const viewAppNode = (appKey: number) => {
    setModalProps({
      selId: appKey,
      modalType: "view",
      modalId: Math.random(),
    });
    setShowModal(true);
  };

  const closeModal = () => {
    setModalProps({
      selId: 0,
      modalType: "new",
      modalId: 0,
    });
    setShowModal(false);
  };

  const onValidSubmission = (message: string) => {
    closeModal();
    notifierFunc(message, true);
    setLoader(true);
    fetchAppNodes();
  };

  const fetchAppNodes = () => {
    axios
      .get("/Apps/GetAllApps")
      .then((response) => {
        const result = response.data as Models.AppsFetch;
        setLoader(false);
        setNodes(result.appList);
      })
      .catch((error: Error) => {
        setLoader(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    fetchAppNodes();
  }, []);

  return (
    <div>
      {!loader ? (
        <>
          <h3 className="nodetitleHeader">App Node Management</h3>
          <Container fluid>
            <Row>
              <Col lg={1}></Col>
              <Col lg={10}>
                <div className="pageContainer shadow">
                  <div className="nodeTableHeaderBuffer">
                    <AntfLinkButton
                      text="New App Node"
                      color="primary"
                      faIcon="fa-plus-circle"
                      classes="newAppBtnProps"
                      onClickAction={createNewNode}
                    />
                    {nodes.length > 0 ? (
                      <Paper>
                        <TableContainer>
                          <Table stickyHeader aria-label="sticky table">
                            <AppNodeHeader />
                            <TableBody>
                              {nodes
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <AppNodeRow
                                    key={Math.random()}
                                    nodeID={row.id}
                                    nodeKey={row.appKey}
                                    nodeName={row.appName}
                                    nodeType={row.appType}
                                    message={row.message}
                                    owner={row.appOwner}
                                    created={row.created}
                                    isInactive={row.isInactive}
                                    notifierFunc={notifierFunc}
                                    viewAppNodeFunc={viewAppNode}
                                  />
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={nodes.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    ) : (
                      <NoDataView message="No App Nodes Created Yet" />
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={1}></Col>
            </Row>

            <AppNodeModal
              modalVisible={showModal}
              notifierFunc={notifierFunc}
              modalProps={modalProps}
              closeModalFunc={closeModal}
              submitFunc={onValidSubmission}
              globalErrorHandler={globalErrorHandler}
            />
          </Container>
        </>
      ) : (
        <LoadingBars />
      )}
    </div>
  );
}

export default AppNodeManager;
