import React from "react";
import { Container, Row } from "react-bootstrap";
import Palms from "../../assets/NoData.png";
import "./NoDataView.css";

interface NoDataViewProps {
  message: string;
}

function NoDataView({ message }: NoDataViewProps) {
  return (
    <>
      <Container className="noDataContainer" fluid>
        <Row>
          <img
            className="palmImgSize palmImgProps"
            src={Palms}
            alt="Palm Trees"
          />
          <h3 className="noDataTextProps antfSecondaryColor">{message}</h3>
        </Row>
      </Container>
    </>
  );
}

export default NoDataView;
