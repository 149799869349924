export async function checkFileBase64(file: File) {
  let promise = getBase64(file);
  return await promise;
}

function getBase64(file: File) {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function validateFile(file: File, maxSize: number): string {
  let returnResult = "" as string;
  const allowedExtensions = [".png", ".jpg", ".JPG", ".PNG"] as string[];
  const fname = file.name as string;
  const fileExt = fname.substring(fname.length - 4) as string;
  if (!allowedExtensions.includes(fileExt)) {
    returnResult = "Please choose a valid png or jpg image file";
  }

  if (file.size > maxSize) {
    returnResult = "Sorry: Image Filesize Too Large (May not exceed 3mb).";
  }

  return returnResult;
}

export function downloadFile(
  fileName: string,
  data: any,
  fileFormat: string
): void {
  const linkSource = "data:" + fileFormat + ";base64" + data;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
