import React from "react";
import LoadingIcon from "../../assets/LoadingBars.gif";
import "./LoadingBars.css";

function LoadingBars() {
  return (
    <div>
      <img
        src={LoadingIcon}
        className="mx-auto d-block imgHeight"
        alt="Loading Bars"
      />
    </div>
  );
}

export default LoadingBars;
