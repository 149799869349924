import React from "react";
import { Card } from "react-bootstrap";
import "./WorkNote.css";

interface WorkNoteProps {
  username: string;
  noteDate: string;
  noteData: string;
}

function WorkNote({ username, noteDate, noteData }: WorkNoteProps) {
  const noteIcon =
    username === "You"
      ? "fa fa-comment-o blurbIconProps"
      : ("fa fa-comments-o blurbIconProps" as string);

  const headerColor =
    username === "You"
      ? "youBlubHeader noteBlurbTitleProps"
      : ("themBlurbHeader noteBlurbTitleProps" as string);

  return (
    <>
      <Card className="noteBlurbProps">
        <Card.Header className={headerColor}>
          <i className={noteIcon}></i>
          {username} @ {noteDate}
        </Card.Header>
        <Card.Text>
          <span className="noteDataBlurbProps wrapTextBreak">{noteData}</span>
        </Card.Text>
      </Card>
    </>
  );
}

export default WorkNote;
