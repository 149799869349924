import { Button } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import "./AppNodeDeleteModal.css";

interface DeleteNodeProps {
  deleteFunc: (appKey: string | undefined) => void;
  appNodeKey: string | undefined;
  closeModalFunc: () => void;
}

function DeleteNodeModal({
  deleteFunc,
  appNodeKey,
  closeModalFunc,
}: DeleteNodeProps) {
  return (
    <>
      <Modal.Body>
        <p className="centerText">
          <i className="fa fa-trash delMainIcon" />
        </p>
        <p className="centerText delMsgText">
          Are you sure you want to Permanently delete this App node?
        </p>
        <p className="centerText delMsgText alertText">
          This cannot be Undone!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="contained"
          color="secondary"
          className="delModalBtn"
          onClick={() => closeModalFunc()}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => deleteFunc(appNodeKey)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </>
  );
}

export default DeleteNodeModal;
