import * as React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import * as Models from "../../models/GlobalModels";

interface SimpleDropDownProps {
  controlRenderType: "standard" | "outlined" | "filled" | undefined;
  title: string;
  cssClass?: string;
  value: string;
  disabled?: boolean;
  data: Models.DataInstance[];
  onChangeFunc: (value: React.SetStateAction<string>) => void;
}

function SimpleDropDown({
  title,
  cssClass,
  value,
  data,
  disabled,
  controlRenderType,
  onChangeFunc,
}: SimpleDropDownProps) {
  function handleChange(event: any) {
    onChangeFunc(event.target.value);
  }

  return (
    <>
      <FormControl
        variant={controlRenderType}
        className={cssClass}
        disabled={disabled}
      >
        <InputLabel>{title}</InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          inputProps={{
            MenuProps: { disableScrollLock: true },
          }}
        >
          {data?.map((item) => (
            <MenuItem key={Math.random()} value={item.keyValue}>
              {item.keyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default SimpleDropDown;
