export const appNodeTypes = [
  "Web App",
  "Desktop App",
  "Mobile App",
  "Server Process",
  "Other",
] as string[];

export const appNodeMessages = [
  "Alert",
  "Warning",
  "Information",
  "Maintenance",
] as string[];
