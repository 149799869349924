import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./IncResolution.css";

interface IncResolutionProps {
  closedTime: string;
  resNotes: string;
  closeIncFunc: (notes: string) => void;
}

function IncResolution({
  closedTime,
  resNotes,
  closeIncFunc,
}: IncResolutionProps) {
  const [resNoteData, setResNoteData] = React.useState<string | undefined>(
    resNotes
  );
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [resVisible, setResVisible] = React.useState<boolean>(false);
  const ticketClosed = (String(resNotes).length > 0) as boolean;

  const closeTimestamp =
    closedTime !== undefined && closedTime !== ""
      ? closedTime
      : ("N/A" as string);

  const resolutionText = ticketClosed
    ? "Resolution Notes"
    : ("Your resolution notes go here" as string);
  const bodyText = hasError
    ? "Validation Error: Resolution Notes cannot be blank"
    : (resolutionText as string);

  const handleResTextChange = (textData: string) => {
    if (textData.length > 0 || String(resNoteData).length > 0) {
      setHasError(false);
    }
    setResNoteData(textData);
  };

  const closeResSection = () => {
    setResNoteData("");
    setHasError(false);
    setResVisible(false);
  };

  const CloseTicket = () => {
    if (String(resNoteData).length > 0) {
      closeIncFunc(String(resNoteData));
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (ticketClosed) {
      setResVisible(true);
    }
  }, [ticketClosed]);

  return (
    <>
      <div className="rightText resContainerPadding">
        {!ticketClosed ? (
          <p className="resLinkProps" onClick={() => setResVisible(true)}>
            <i className="fa fa-flag resIcoBtn" title="Resolve Incident"></i>
            Mark Incident as Resolved
          </p>
        ) : (
          <></>
        )}
      </div>
      {resVisible ? (
        <Container className="resContainerProps" fluid>
          <Row>
            <Col sm={2} md={2} lg={2}></Col>
            <Col sm={8} md={8} lg={8}>
              <div>
                <h5 className="centerText ticketNotesHeader antfColor">
                  Incident WorkNotes
                </h5>
                <TextField
                  id="outlined-multiline-flexible"
                  name="noteText"
                  className="controlWidth resMarginTop"
                  onChange={(e) => handleResTextChange(e.target.value)}
                  value={resNoteData}
                  required
                  error={hasError}
                  label={bodyText}
                  disabled={closeTimestamp !== "N/A"}
                  multiline
                  rows={10}
                  variant="outlined"
                />
                <p className="antfColor resMarginTop">
                  <i className="fa fa-clock-o resTimeIcon"></i>
                  <span className="boldParagraph">Ticket Closed: </span>
                  {closeTimestamp}
                </p>
              </div>
            </Col>
            <Col sm={2} md={2} lg={2}></Col>
          </Row>
          {!ticketClosed ? (
            <div className="rightText resContainerPadding">
              <p className="resLinkProps" onClick={() => closeResSection()}>
                <i
                  className="fa fa-ban resIcoBtn"
                  title="Cancel Resolving Incident"
                ></i>
                Cancel
              </p>
              <p className="resLinkProps" onClick={() => CloseTicket()}>
                <i
                  className="fa fa-thumbs-up resIcoBtn"
                  title="Close Incident"
                ></i>
                Finalize &amp; Close Ticket
              </p>
            </div>
          ) : (
            <></>
          )}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}

export default IncResolution;
