import React, { useEffect } from "react";
import Badge from "@mui/material/Badge";
import * as Models from "../../models/GlobalModels";
import useApi from "../../api/useApi";
import NotificationsIcon from "@mui/icons-material/Notifications";

interface BellIconProps {
  navigateTo: (pageName: string) => void;
  globalErrorHandler: any;
}

function BellIcon({ globalErrorHandler, navigateTo }: BellIconProps) {
  const axios = useApi();
  const [msgCount, setMsgCount] = React.useState<Number>(0);

  const getMessageCount = () => {
    axios
      .get("/Messages/GetUnreadMessageCount")
      .then((response) => {
        const result = response.data as Models.CountResult;
        setMsgCount(result.count);
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    getMessageCount();
  });

  useEffect(() => {
    setInterval(() => {
      getMessageCount();
    }, 15000);
  });

  return (
    <div>
      <Badge
        badgeContent={msgCount}
        color="primary"
        onClick={() => navigateTo("my-messages")}
      >
        <NotificationsIcon style={{ color: "white" }} />
      </Badge>
    </div>
  );
}

export default BellIcon;
