import React from "react";
import { Card, Row } from "react-bootstrap";
import "./NotificationBubble.css";

interface NotificationBubbleProp {
  icon: string;
  subject: string;
  notificationType: string;
  datestamp: string;
  itemKey: number;
  messageRead: boolean;
  clickHandler: React.Dispatch<React.SetStateAction<number>>;
}

function NotificationBubble({
  icon,
  subject,
  notificationType,
  datestamp,
  itemKey,
  clickHandler,
  messageRead,
}: NotificationBubbleProp) {
  const msgIcon = messageRead ? "mutedGray" : ("activeMsg" as string);

  const iconClass = `fa ${icon} ${msgIcon}` as string;

  const cardCss = messageRead
    ? "msgRead messageSpacing shadow rounded"
    : ("messageSpacing shadow rounded" as string);

  return (
    <Card className={cardCss} onClick={() => clickHandler(itemKey)}>
      <Card.Body>
        <span className="pull-left msgIconProps">
          <i className={iconClass}></i>
        </span>
        <Row>
          <Card.Text>
            <span className="pull-left titleHeaderProps boldGray">
              {subject}
            </span>
            <span className="dateProps shimmerGray">{datestamp}</span>
          </Card.Text>
        </Row>
        <Row>
          <Card.Text>
            <span className="messageProps shimmerGray">{notificationType}</span>
          </Card.Text>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default NotificationBubble;
