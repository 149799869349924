import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";

function Footer() {
  const currYear = new Date().getFullYear() as number;
  const version = 3.1 as number;
  const toolTipText = `Version: ${version}` as string;
  return (
    <div className="footerProps">
      <Container fluid>
        <Row>
          <Col lg={1} className="mobilePage">
            <p className="leftText footerText">
              <i className="fa fa-flag" title={toolTipText}></i> {version}
            </p>
          </Col>
          <Col lg={11}>
            <p className="centerText footerText rowCorrect">
              <i className="fa fa-copyright"></i> {currYear} ANTF Group.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
