/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as Models from "../../../models/GlobalModels";
import "./ClientKeyManagement.css";
import useApi from "../../../api/useApi";
import NoDataView from "../../../components/noDataView/NoDataView";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import AntfLinkButton from "../../../components/buttons/antfLinkButton/AntfLinkButton";
import ClientKeyHeader from "../../../components/tables/headers/clientKeys/ClientKeyHeader";
import ClientKeyRow from "../../../components/tables/rows/clientKeys/ClientKeyRow";
import LoadingBars from "../../../components/loading/LoadingBars";
import ClientKeysModal from "../../../components/modals/clientKeysModal/ClientKeysModal";
import * as DataUtils from "../../../utils/DataConversionUtils";

interface props {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
}

function ClientKeyManagement({ globalErrorHandler, notifierFunc }: props) {
  const [keys, setKeys] = React.useState<Models.UIClientKey[]>([]);
  const [selectedKey, setSelectedKey] = React.useState<
    Models.UIClientKey | undefined
  >(undefined);
  const axios = useApi();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = React.useState<boolean>(true);
  const [showDisabled, setShowDisabled] = React.useState<boolean>(false);
  const [modalProps, setModalProps] = React.useState<Models.GenericModal>({
    show: false,
    modalType: "new",
    modalId: "",
  });

  const fetchClientKeys = (onlyDisabled: boolean) => {
    setLoader(true);
    axios
      .get(`/ClientManagement/GetClientKeys/${onlyDisabled}`)
      .then((response) => {
        const result = response.data as Models.UIClientKey[];
        setKeys(result);
        setLoader(false);
      })
      .catch((error: Error) => {
        setLoader(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const fetchRecord = (appKey: string) => {
    if (!DataUtils.IsNullOrEmpty(appKey)) {
      axios
        .get(`/ClientManagement/GetClientKeyById/${appKey}`)
        .then((response) => {
          const result = response.data as Models.UIClientKey;
          if (result !== null) {
            setSelectedKey(result);
          }
        })
        .catch((error: Error) => {
          globalErrorHandler(() => {
            throw error;
          });
        });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const createNewClientKey = () => {
    setModalProps({
      show: true,
      modalType: "new",
      modalId: "",
    });
  };

  const viewClientKey = (appKey: string) => {
    fetchRecord(appKey);
    setModalProps({
      show: true,
      modalType: "edit",
      modalId: appKey,
    });
  };

  const onModalSuccess = () => {
    setModalProps({
      show: false,
      modalType: "new",
      modalId: "",
    });
    fetchClientKeys(showDisabled);
  };

  useEffect(() => {
    fetchClientKeys(showDisabled);
  }, [showDisabled]);

  return (
    <>
      {!loader ? (
        <>
          <h3 className="nodetitleHeader">App Client Key Management</h3>
          <Container fluid>
            <ClientKeysModal
              modalProps={modalProps}
              handleSuccessEvent={onModalSuccess}
              clientKeyObj={selectedKey}
              globalErrorHandler={globalErrorHandler}
              modalPropsEvent={setModalProps}
              clientKeyObjEvent={setSelectedKey}
            />

            <Row>
              <Col lg={1} />
              <Col lg={10}>
                <div className="pageContainer shadow">
                  <div className="nodeTableHeaderBuffer">
                    <Row>
                      <Col lg={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showDisabled}
                              value={showDisabled}
                              onChange={(e) =>
                                setShowDisabled(e.target.checked)
                              }
                            />
                          }
                          label="Show Disabled Client Keys"
                        />
                      </Col>
                      <Col lg={6} />
                      <Col lg={3}>
                        <AntfLinkButton
                          text="New Client Key"
                          color="primary"
                          faIcon="fa-plus-circle"
                          classes="newAppBtnProps"
                          onClickAction={createNewClientKey}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        {keys.length > 0 ? (
                          <Paper>
                            <TableContainer>
                              <Table stickyHeader aria-label="sticky table">
                                <ClientKeyHeader />
                                <TableBody>
                                  {keys
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row) => (
                                      <ClientKeyRow
                                        key={Math.random()}
                                        keyID={row.id}
                                        clientKey={row.key}
                                        clientName={row.clientName}
                                        appName={row.appName}
                                        created={row.created}
                                        isInactive={row.disabled}
                                        notifierFunc={notifierFunc}
                                        viewAppNodeFunc={viewClientKey}
                                      />
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={keys.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </Paper>
                        ) : (
                          <NoDataView message="No App Client Keys Created Yet" />
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={1} />
            </Row>
          </Container>
        </>
      ) : (
        <>
          <LoadingBars />
        </>
      )}
    </>
  );
}

export default ClientKeyManagement;
