import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as TableStyles from "../../../../themes/TableTheme";

function MuiHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableStyles.StyledTableCell align="center">
          App&nbsp;Name
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          App&nbsp;Key
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Message
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          App&nbsp;Type
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Owner
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Created
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Status
        </TableStyles.StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default MuiHeader;
