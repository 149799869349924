/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Checkbox,
} from "@material-ui/core";
import "./TicketExplorer.css";
import { useHistory } from "react-router-dom";
import useApi from "../../../api/useApi";
import SimpleDropDown from "../../../components/simpleDropDown/SimpleDropDown";
import * as Models from "../../../models/GlobalModels";
import SearchField from "../../../components/searchField/SearchField";
import { criteriaFilterDropdown } from "../../../data/CriteriaFilterDropdown";
import MyTicketHeader from "../../../components/tables/headers/myTickets/MyTicketHeader";
import MyTicketsRow from "../../../components/tables/rows/myTickets/MyTicketsRow";
import LoadingBars from "../../../components/loading/LoadingBars";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

interface TicketExplorerProps {
  notifierFunc: (message: string, success: boolean) => void;
  globalErrorHandler: any;
}

function TicketExplorer({
  notifierFunc,
  globalErrorHandler,
}: TicketExplorerProps) {
  const classes = useStyles();
  const axios = useApi();
  const history = useHistory();
  const [loader, setLoader] = React.useState<boolean>(true);
  const [inactiveEvents, setInactiveEvents] = React.useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = React.useState<string>("");
  const [filterOptions] = React.useState<Models.DataInstance[]>(
    criteriaFilterDropdown
  );
  const [tickets, setTickets] = React.useState<Models.UITicket[]>([]);
  const [apiTickets, setApiTickets] = React.useState<Models.UITicket[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEventActivity = (eventDat: boolean) => {
    setInactiveEvents(eventDat);
    setTickets(GetFilteredInactiveTickets(apiTickets, eventDat));
  };

  const GetFilteredInactiveTickets = (
    objArray: Models.UITicket[],
    activeFlag: boolean
  ) => {
    let deepCopyObj = JSON.parse(JSON.stringify(objArray)) as Models.UITicket[];
    return [
      ...deepCopyObj.filter((x) => x.activeTicket === !activeFlag),
    ] as Models.UITicket[];
  };

  const searchRequest = (input: string) => {
    if (selectedFilter.length > 0) {
      searchTickets(input);
    } else {
      notifierFunc("Cannot leave filter option empty.", false);
    }
  };

  const searchTickets = (input: string) => {
    setLoader(true);
    const request = `/Tickets/GetAllTicketsByFilterCriteria/${selectedFilter}/${
      input.length === 0 ? "*" : input
    }` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.TicketsFetch;
        setLoader(false);
        if (result.count > 0) {
          setApiTickets(result.tickets);
          const tempTicket = GetFilteredInactiveTickets(
            result.tickets,
            inactiveEvents
          ) as Models.UITicket[];
          setTickets(tempTicket);
          notifierFunc(`${tempTicket.length} search result(s) found!`, true);
        } else {
          setTickets([]);
          setApiTickets([]);
          notifierFunc("No search results found", false);
        }
      })
      .catch((error: Error) => {
        setLoader(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const getFilterPlaceholder = (input: string) => {
    if (input.length !== 0) {
      return `Filter search based on ${input}` as string;
    } else {
      return "Filter search descriptor" as string;
    }
  };

  const onTicketSelection = (ticketNum: string) => {
    history.push({
      pathname: "./my-ticket",
      search: `?incident=${ticketNum}`,
    });
  };

  const getTickets = () => {
    axios
      .get("/Tickets/GetAllTickets")
      .then((response) => {
        const result = response.data as Models.TicketsFetch;
        setLoader(false);
        setTickets(GetFilteredInactiveTickets(result.tickets, inactiveEvents));
        setApiTickets(result.tickets);
      })
      .catch((error: Error) => {
        setLoader(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    getTickets();
  }, []);

  return (
    <div>
      <h3 className="nodetitleHeader">Master Ticket Explorer</h3>
      <Container fluid>
        <Row>
          <Col lg={1}></Col>
          <Col lg={10}>
            <div className="pageContainer shadow">
              <div className="nodeTableHeaderBuffer">
                <Row>
                  <Col lg={4}>
                    <FormControlLabel
                      className="inactiveTicketsLoc"
                      control={
                        <Checkbox
                          checked={inactiveEvents}
                          disabled={loader}
                          onChange={(e) =>
                            handleEventActivity(e.target.checked)
                          }
                        />
                      }
                      label="Show Only Resolved/Closed Tickets"
                    />
                  </Col>
                  <Col lg={2}></Col>
                  <Col lg={2}>
                    <SimpleDropDown
                      controlRenderType="standard"
                      title="Filter Criteria"
                      cssClass="controlWidth"
                      value={selectedFilter}
                      data={filterOptions}
                      disabled={loader}
                      onChangeFunc={setSelectedFilter}
                    />
                  </Col>
                  <Col lg={4}>
                    <SearchField
                      searchButtonText="Search"
                      searchText={getFilterPlaceholder(selectedFilter)}
                      searchFunc={searchRequest}
                      validationExceptionKeyword={
                        selectedFilter.length > 0 &&
                        selectedFilter.toLowerCase() === "all"
                      }
                      allowNullSearch={false}
                      disabled={loader}
                      cssClass="searchField"
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <>
                    {!loader ? (
                      <>
                        <Paper className={classes.root}>
                          <TableContainer className={classes.root}>
                            <Table stickyHeader aria-label="sticky table">
                              <MyTicketHeader />
                              <TableBody>
                                {tickets
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row) => (
                                    <MyTicketsRow
                                      key={Math.random()}
                                      ticketID={row.id}
                                      description={row.description}
                                      category={row.category}
                                      status={row.activeTicket}
                                      opened={row.created}
                                      attachment={row.hasAttachment}
                                      severity={row.severity}
                                      ticketSelectionFunc={onTicketSelection}
                                    />
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={tickets.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      </>
                    ) : (
                      <>
                        <LoadingBars />
                      </>
                    )}
                  </>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default TicketExplorer;
