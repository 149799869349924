/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Checkbox,
} from "@material-ui/core";
import useApi from "../../api/useApi";
import { Container, Row, Col } from "react-bootstrap";
import * as Models from "../../models/GlobalModels";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MyTicketHeader from "../../components/tables/headers/myTickets/MyTicketHeader";
import MyTicketsRow from "../../components/tables/rows/myTickets/MyTicketsRow";
import NoDataView from "../../components/noDataView/NoDataView";
import LoadingBars from "../../components/loading/LoadingBars";
import "./MyTickets.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

interface MyTicketsProps {
  notifierFunc: (message: string, success: boolean) => void;
  globalErrorHandler: any;
}

function MyTickets({ notifierFunc, globalErrorHandler }: MyTicketsProps) {
  const classes = useStyles();
  const axios = useApi();
  const history = useHistory();

  const [tickets, setTickets] = React.useState<Models.UITicket[]>([]);
  const [loader, setLoader] = React.useState<boolean>(true);
  const [titleText, setTitleText] = React.useState<string>("My Open Tickets");
  const [inactiveTicketsOnly, setInactiveTicketsOnly] =
    React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onTicketSelection = (ticketNum: string) => {
    history.push({
      pathname: "./my-ticket",
      search: `?incident=${ticketNum}`,
    });
  };

  const handleInactiveSelection = (checked: boolean) => {
    setInactiveTicketsOnly(checked);
    handleTitleChange(checked);
    getTickets(checked);
  };

  const handleTitleChange = (checked: boolean) => {
    const newTitle = checked
      ? "My Resolved/Closed Tickets"
      : ("My Open Tickets" as string);
    setTitleText(newTitle);
  };

  const getTickets = (showInactive: boolean) => {
    let request = "" as string;
    if (showInactive) {
      request = "/Tickets/GetResolvedUserTickets";
    } else {
      request = "/Tickets/GetActiveUserTickets";
    }

    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.TicketsFetch;
        setLoader(false);
        setTickets(result.tickets);
      })
      .catch((error: Error) => {
        setLoader(false);
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    getTickets(false);
  }, []);

  return (
    <div>
      {!loader ? (
        <>
          <h3 className="titleHeader">{titleText}</h3>
          {tickets.length > 0 ? (
            <Container fluid>
              <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                  <div className="pageContainer shadow">
                    <div className="tableHeaderBuffer">
                      <Paper className={classes.root}>
                        <TableContainer className={classes.root}>
                          <Table stickyHeader aria-label="sticky table">
                            <MyTicketHeader />
                            <TableBody>
                              {tickets
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <MyTicketsRow
                                    key={Math.random()}
                                    ticketID={row.id}
                                    description={row.description}
                                    category={row.category}
                                    status={row.activeTicket}
                                    opened={row.created}
                                    attachment={row.hasAttachment}
                                    severity={row.severity}
                                    ticketSelectionFunc={onTicketSelection}
                                  />
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={tickets.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                      <FormControlLabel
                        className="inactiveTicketsLoc"
                        control={
                          <Checkbox
                            checked={inactiveTicketsOnly}
                            onChange={(e) =>
                              handleInactiveSelection(e.target.checked)
                            }
                          />
                        }
                        label="Show Closed/Resolved Tickets"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={1}></Col>
              </Row>
            </Container>
          ) : (
            <>
              <NoDataView message="Relax, you have no active tickets." />
            </>
          )}
        </>
      ) : (
        <LoadingBars />
      )}
    </div>
  );
}

export default MyTickets;
