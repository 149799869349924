import React from "react";
import * as Models from "../../../models/GlobalModels";
import { Container, Row, Col } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import * as TableStyles from "../../../themes/TableTheme";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Paper from "@mui/material/Paper";
import "./EventLogViewer.css";

interface EventLogViewerProps {
  eventLog: Models.UISentinelEvent | undefined;
  toggleResolvedFunc: (eventId: string, resolved: boolean) => void;
}

function EventLogViewer({ eventLog, toggleResolvedFunc }: EventLogViewerProps) {
  const handleCheckChange = (status: boolean) => {
    if (eventLog?.eventID !== undefined) {
      toggleResolvedFunc(eventLog?.eventID, status);
    }
  };

  const resolveLabel = eventLog?.isResolved
    ? "Mark as Unresolved"
    : ("Mark as Resolved" as string);

  return (
    <div className="eventComProps">
      <Container className="eventRowPadding" fluid>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <h2 className="antfColor">Error Summary</h2>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <FormGroup className="floatObjRight resolveSwitchProps">
              <FormControlLabel
                control={
                  <Switch
                    checked={eventLog?.isResolved}
                    onChange={(e) => handleCheckChange(e.target.checked)}
                    color="secondary"
                  />
                }
                label={resolveLabel}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="leftText">
              <strong className="antfSecondaryColor">App: </strong>
              {eventLog?.applicationName}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <p className="leftText">
              <strong className="antfSecondaryColor">EventID: </strong>
              {eventLog?.eventID}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <p className="rightText">
              <strong className="antfSecondaryColor">Created: </strong>
              {eventLog?.created}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <p className="leftText">
              <strong className="antfSecondaryColor">Username: </strong>
              {eventLog?.username}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <p className="rightText">
              <strong className="antfSecondaryColor">Resolved: </strong>
              {eventLog?.resolved}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <p className="leftText">
              <strong className="antfSecondaryColor">Machine Name: </strong>
              {eventLog?.machineName}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <p className="rightText">
              <strong className="antfSecondaryColor">URL Address: </strong>
              {eventLog?.urlAddress}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <p className="leftText">
              <strong className="antfSecondaryColor">
                Internal IP Address:{" "}
              </strong>
              {eventLog?.ipAddress}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <p className="rightText">
              <strong className="antfSecondaryColor">
                External IP Address:{" "}
              </strong>
              {eventLog?.publicIpAddress}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <p className="leftText">
              <strong className="antfSecondaryColor">Error Type: </strong>
              {eventLog?.errorType}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <p className="rightText">
              <strong className="antfSecondaryColor">Tag Name: </strong>
              {eventLog?.tagName}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <p className="leftText">
              <strong className="antfSecondaryColor">Platform: </strong>
              {eventLog?.platform}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <p className="rightText">
              <strong className="antfSecondaryColor">Version: </strong>
              {eventLog?.version}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <p className="leftText">
              <strong className="antfSecondaryColor">OS: </strong>
              {eventLog?.system}
            </p>
          </Col>
        </Row>
      </Container>

      <>
        {eventLog?.errorMessage ? (
          <div>
            <h5 className="antfSecondaryColor">Error Message</h5>
            <p>{eventLog?.errorMessage}</p>
          </div>
        ) : (
          <></>
        )}
      </>

      <>
        {eventLog?.innerException ? (
          <div>
            <h5 className="antfSecondaryColor">Inner Exception</h5>
            <p>{eventLog?.innerException}</p>
          </div>
        ) : (
          <></>
        )}
      </>

      <>
        {eventLog?.stackTrace ? (
          <div>
            <h5 className="antfSecondaryColor">Stack Trace</h5>
            <p>{eventLog?.stackTrace}</p>
          </div>
        ) : (
          <></>
        )}
      </>

      <div className="eventMetaDataProps">
        <h5 className="antfSecondaryColor">Captured Meta Data</h5>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableStyles.StyledTableCell align="center">
                  Key
                </TableStyles.StyledTableCell>
                <TableStyles.StyledTableCell align="center">
                  Value
                </TableStyles.StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventLog?.metaDataProps.map((row) => (
                <TableStyles.StyledTableRow key={Math.random()}>
                  <TableStyles.StyledTableCell
                    align="center"
                    component="th"
                    scope="row"
                  >
                    {row.dataKey}
                  </TableStyles.StyledTableCell>
                  <TableStyles.StyledTableCell align="center">
                    {row.dataValue}
                  </TableStyles.StyledTableCell>
                </TableStyles.StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default EventLogViewer;
