import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ComSettings from "../../components/settings/comSettings/ComSettings";
import "./Settings.css";

interface SettingsProps {
  globalErrorHandler: any;
  notifierFunc: (message: string, success: boolean) => void;
}

function Settings({ globalErrorHandler, notifierFunc }: SettingsProps) {
  return (
    <div>
      <h3 className="nodetitleHeader">My Profile Settings</h3>
      <Container fluid>
        <Row>
          <Col lg={1}></Col>
          <Col lg={10}>
            <div className="pageContainer shadow">
              <div className="nodeTableHeaderBuffer">
                <Row>
                  <Col lg={6}>
                    <ComSettings
                      globalErrorHandler={globalErrorHandler}
                      notifierFunc={notifierFunc}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Settings;
