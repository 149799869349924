import React from "react";
import * as TableStyles from "../../../../themes/TableTheme";
import * as CssUtils from "../../../../utils/CssUtils";
import "./MyTicketsRow.css";

interface MyTicketsRowProps {
  ticketID: string;
  description: string;
  category: string;
  status: boolean;
  opened: string;
  attachment: boolean;
  severity: string;
  ticketSelectionFunc: (ticketNum: string) => void;
}

function MyTicketsRow({
  ticketID,
  description,
  category,
  status,
  opened,
  attachment,
  severity,
  ticketSelectionFunc,
}: MyTicketsRowProps) {
  const cssColor = CssUtils.GetTicketSeverityColor(severity) as string;
  const activeStatus = status ? "Open" : ("Closed" as string);
  const desc =
    description.length > 16
      ? `${description.substr(0, 13)}...`
      : (description as string);

  return (
    <>
      <TableStyles.StyledTableRow hover tabIndex={-1}>
        <TableStyles.StyledTableCell
          component="th"
          align="center"
          scope="row"
          onClick={() => ticketSelectionFunc(ticketID)}
        >
          <span className="idField">{ticketID}</span>
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {desc}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {category}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {opened}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {attachment ? (
            <p>
              <i
                title="Incident has an attachment"
                className="fa fa-paperclip attachmentIcon"
              ></i>
            </p>
          ) : (
            <></>
          )}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          <p className={cssColor}>{severity}</p>
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {activeStatus}
        </TableStyles.StyledTableCell>
      </TableStyles.StyledTableRow>
    </>
  );
}

export default MyTicketsRow;
