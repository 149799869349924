import * as Models from "../models/GlobalModels";
import * as AppNodesData from "../data/IncidentDropdownDetails";

export function ConvertToTicket(
  submitObject: Models.FormikTicket
): Models.UITicket {
  const newTicket = {
    id: "",
    username: submitObject.username,
    email: submitObject.email,
    phone: submitObject.phone,
    category: submitObject.category,
    description: submitObject.description,
    subCategory: submitObject.subCategory,
    severity: submitObject.severity,
    errorMsg: submitObject.errorCode,
    notes: submitObject.notes,
    activeTicket: true,
    created: "",
    closed: "",
    resolutionNotes: "",
    viewedBy: "",
    modifiedBy: "",
    isAutoPopulated: submitObject.isAutoPopulated,
  } as Models.UITicket;
  return newTicket;
}

//Returns string in the date format of '2022-05-14' which is readable by .Net
export function GetCurrentDotNetDateString(): string {
  const dateObj = new Date();
  const day =
    dateObj.getDate().toString().length < 2
      ? `0${dateObj.getDate()}`
      : (`${dateObj.getDate()}` as string);

  const month =
    (dateObj.getMonth() + 1).toString().length < 2
      ? `0${dateObj.getMonth() + 1}`
      : (`${dateObj.getMonth() + 1}` as string);

  const dateString = `${dateObj.getFullYear()}-${month}-${day}` as string;
  return dateString;
}

// Returns Date object of today with the timne zero'd out
export function GetTodaysDateOnly(): Date {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function ConvertDotNetDateToJsDate(dateString: string | undefined) {
  if (
    dateString === null ||
    dateString === undefined ||
    dateString.length === 0
  ) {
    return dateString;
  }

  const datePart = dateString.substring(0, dateString.indexOf(" ")) as string;
  const month = datePart.substring(0, datePart.indexOf("/")) as string;
  const year = datePart.substring(datePart.lastIndexOf("/") + 1) as string;
  const dayTemp = datePart.replace(`/${year}`, "") as string;
  const day = dayTemp.substring(dayTemp.lastIndexOf("/") + 1) as string;
  const dayStamp = parseInt(day, 10) as number;
  const formattedDayStamp =
    dayStamp < 10 && day.includes("0") ? day : (`0${day}` as string);
  const monthStamp = (
    parseInt(month, 10) < 10 && !month.includes("0") ? `0${month}` : month
  ) as string;

  const newDate = `${year}-${monthStamp}-${
    parseInt(day, 10) < 10 ? formattedDayStamp : day
  }` as string;

  return newDate;
}

export function InjectAllIntoDataInstanceList(
  text: string,
  items: Models.DataInstance[]
): Models.DataInstance[] {
  const newDataIns = {
    keyName: text,
    keyValue: GetEmptyGuid(),
  } as Models.DataInstance;
  const newItems = [newDataIns].concat(items);
  return newItems;
}

export function GetEmptyGuid(): string {
  return "00000000-0000-0000-0000-000000000000";
}

export function IsNullOrEmpty(input: string | undefined | null) {
  return input === null || input === undefined || input?.trim() === "";
}

export function ConvertToAttachment(
  submitObject: Models.ImgAttachment
): Models.UIAttachment {
  const newFile = {
    id: 0,
    ticketID: "",
    imageData: submitObject.image,
    imageName: submitObject.title,
    type: GetExtensionFromFilename(submitObject.title),
    created: "",
  } as Models.UIAttachment;
  return newFile;
}

export function BuildTicketSubmissionPayload(
  ticketObj: Models.UITicket,
  attachmentObj: Models.UIAttachment
): Models.Incident {
  const inc = {
    ticket: ticketObj,
    screenshot: attachmentObj,
    workNotes: [],
  } as Models.Incident;
  return inc;
}

export function ConvertToNewIncident(
  ticketObj: Models.UITicket,
  attachment: Models.UIAttachment
): Models.Incident {
  const inc = {
    ticket: ticketObj,
    screenshot: attachment,
    workNotes: [],
  } as Models.Incident;
  return inc;
}

export function ConvertTextToShorthand(text: string, limit: number): string {
  if (text !== undefined && text.length > 0 && limit <= text.length) {
    const realLimit = (limit - 3) as number;
    const subset = text.substring(0, realLimit);
    return `${subset}...`;
  }
  return text;
}

function GetExtensionFromFilename(fileName: string): string {
  if (fileName === undefined || fileName === "") {
    return "";
  }
  const ext = fileName.split(".").pop() as string;
  return ext.toLowerCase();
}

export function ConvertSentinelEventToAutoFill(
  event: Models.UISentinelEvent
): Models.TicketAutofill {
  if (event !== undefined) {
    const newEvent = {
      eventId: event.eventID,
      category: "Application Issues",
      description: `(AutoGen) - ${event.applicationName} Error Reported`,
      subCategory: IsValidAppName(event.applicationName)
        ? event.applicationName
        : "",
      severity: "Medium Impact (Normal)",
      errorMsg: event.errorMessage,
      notes: `See Sentinel Event Log: ${event.eventID}`,
    } as Models.TicketAutofill;
    return newEvent;
  } else {
    return GetBlankAutoFillObject();
  }
}

function IsValidAppName(appName: string): boolean {
  if (appName === undefined || appName.length === 0) {
    return false;
  }

  const appNames = AppNodesData.apps as string[];
  return appNames.includes(appName);
}

export function GetBlankAutoFillObject(): Models.TicketAutofill {
  const newEvent = {
    eventId: "",
    category: "",
    description: "",
    subCategory: "",
    severity: "",
    errorMsg: "",
    notes: "",
  } as Models.TicketAutofill;
  return newEvent;
}
