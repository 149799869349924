import axios, { AxiosInstance } from "axios";
import { useKeycloak } from "@react-keycloak/web";
import * as envMgr from "../utils/envUtil";

const useApi = (): AxiosInstance => {
  const { keycloak } = useKeycloak();

  const instance = axios.create({
    baseURL: envMgr.getApiBaseConnection(),
  });

  //Not sure if these are needed. Reached out to Charles 2-14-21
  //'Accept': 'application/json',
  //'Content-Type': 'application/x-www-form-urlencoded'

  instance.interceptors.request.use(
    async (config) => {
      const value = keycloak.token;
      config.headers = {
        Authorization: `Bearer ${value}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return instance;
};

export default useApi;
