import React from "react";
import * as TableStyles from "../../../../themes/TableTheme";
import "./AppNodeRow.css";

interface AppNodeRowProps {
  nodeID: number;
  nodeName: string;
  nodeKey: string;
  nodeType: string;
  message: string;
  owner: string;
  created: string;
  isInactive: boolean;
  viewAppNodeFunc: (appKey: number) => void;
  notifierFunc: (message: string, success: boolean) => void;
}

function AppNodeRow({
  nodeID,
  nodeKey,
  nodeName,
  nodeType,
  message,
  owner,
  created,
  isInactive,
  notifierFunc,
  viewAppNodeFunc,
}: AppNodeRowProps) {
  const activeStatus = isInactive ? "Inactive" : ("Active" as string);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(nodeKey);
    notifierFunc("App Node Key Copied to clipboard!", true);
  };

  const [showKey, setShowKey] = React.useState<boolean>(false);
  const hiddenText = "########-####-####-####-############" as string;
  const viskeyCss = "fa fa-eye-slash keyClipboard antfSecondaryColor" as string;
  const nokeyCss = "fa fa-eye keyClipboard antfSecondaryColor" as string;
  const keyCss = showKey ? viskeyCss : (nokeyCss as string);
  const hasMessage =
    message !== null && message !== undefined && message.length > 0 ? (
      <i
        title="This App Node has an Active Message!"
        className="fa fa-exclamation-triangle tableIcon antfErrorColor"
      />
    ) : (
      ((
        <i
          title="This App Node has No Active Message!"
          className="fa fa-check-circle-o tableIcon antfSuccessColor"
        />
      ) as JSX.Element)
    );

  const toggleKeyView = () => {
    setShowKey(!showKey);
  };

  return (
    <>
      <TableStyles.StyledTableRow hover tabIndex={-1}>
        <TableStyles.StyledTableCell component="th" align="center" scope="row">
          <span className="nodeIdField" onClick={() => viewAppNodeFunc(nodeID)}>
            {nodeName}
          </span>
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          <span>
            {showKey ? nodeKey : hiddenText}
            <i
              title="Toogle Show/Hide Key"
              onClick={() => toggleKeyView()}
              className={keyCss}
            />
            <i
              title="Copy Key to Clipboard"
              onClick={() => copyToClipboard()}
              className="fa fa-clipboard keyClipboard antfSecondaryColor"
            />
          </span>
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {hasMessage}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {nodeType}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {owner}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {created}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {activeStatus}
        </TableStyles.StyledTableCell>
      </TableStyles.StyledTableRow>
    </>
  );
}

export default AppNodeRow;
