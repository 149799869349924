/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./App.css";
import NavigationBar from "./components/navigationBar/NavigationBar";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MyHub from "./pages/myHub/MyHub";
import NewTicket from "./pages/newTicket/NewTicket";
import MyTickets from "./pages/myTickets/MyTickets";
import Messages from "./pages/messages/Messages";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { userObject } from "./store/UserContext";
import Footer from "./components/footer/Footer";
import PrivateRoute from "./utils/PrivateRoute";
import { useKeycloak } from "@react-keycloak/web";
import MyTicket from "./pages/myTicket/MyTicket";
import AppNodeManager from "./pages/adminPages/appNodeManager/AppNodeManager";
import NotFound from "./components/notFound/NotFound";
import SentinalLogs from "./pages/adminPages/sentinalLogs/SentinalLogs";
import * as Models from "./models/GlobalModels";
import TicketExplorer from "./pages/adminPages/ticketExplorer/TicketExplorer";
import Settings from "./pages/settings/Settings";
import UsageMonitor from "./pages/adminPages/usageMonitor/UsageMonitor";
import ClientKeyManagement from "./pages/adminPages/clientKeysManager/ClientKeyManagement";

function App() {
  const showToastNotification = (message: string, success: boolean) => {
    if (success) {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };

  const [userObj, setUserObj] =
    useRecoilState<Models.AntfIdentityModel>(userObject);

  const { keycloak } = useKeycloak();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadError, setLoadError] = React.useState();
  const [ticketUrl, setTicketUrl] = React.useState<string>("");

  if (
    !keycloak.profile &&
    keycloak.authenticated &&
    userObj.keyCloakIdentity === undefined
  ) {
    keycloak.loadUserProfile().then((data) => {
      const identityModel = {
        keyCloakIdentity: data,
        hasAdminRole: keycloak.hasRealmRole("admin"),
      } as Models.AntfIdentityModel;
      setUserObj(identityModel);
    });
  }

  return (
    <div className="pageGlobals">
      <ToastContainer />
      <NavigationBar
        globalErrorHandler={setLoadError}
        notifierFunc={showToastNotification}
        pageUrlFunc={setTicketUrl}
      />
      <div className="pageProps">
        <Switch>
          <Route exact path="/">
            <MyHub />
          </Route>
          <Route exact path="/new-ticket">
            <PrivateRoute adminBlock={false}>
              <NewTicket
                notifierFunc={showToastNotification}
                globalErrorHandler={setLoadError}
              />
            </PrivateRoute>
          </Route>
          <Route exact path="/my-tickets">
            <PrivateRoute adminBlock={false}>
              <MyTickets
                notifierFunc={showToastNotification}
                globalErrorHandler={setLoadError}
              />
            </PrivateRoute>
          </Route>
          <Route exact path="/my-messages">
            <PrivateRoute adminBlock={false}>
              <Messages
                notifierFunc={showToastNotification}
                globalErrorHandler={setLoadError}
              />
            </PrivateRoute>
          </Route>
          <Route path="/my-ticket">
            <PrivateRoute adminBlock={false}>
              <MyTicket
                globalErrorHandler={setLoadError}
                notifierFunc={showToastNotification}
                pageUrl={ticketUrl}
              />
            </PrivateRoute>
          </Route>
          <Route path="/settings">
            <PrivateRoute adminBlock={false}>
              <Settings
                globalErrorHandler={setLoadError}
                notifierFunc={showToastNotification}
              />
            </PrivateRoute>
          </Route>
          <Route path="/app-nodes">
            <PrivateRoute adminBlock={true}>
              <AppNodeManager
                globalErrorHandler={setLoadError}
                notifierFunc={showToastNotification}
              />
            </PrivateRoute>
          </Route>
          <Route path="/client-keys">
            <PrivateRoute adminBlock={true}>
              <ClientKeyManagement
                globalErrorHandler={setLoadError}
                notifierFunc={showToastNotification}
              />
            </PrivateRoute>
          </Route>
          <Route path="/ticket-explorer">
            <PrivateRoute adminBlock={true}>
              <TicketExplorer
                globalErrorHandler={setLoadError}
                notifierFunc={showToastNotification}
              />
            </PrivateRoute>
          </Route>
          <Route path="/sentinal-events">
            <PrivateRoute adminBlock={true}>
              <SentinalLogs
                globalErrorHandler={setLoadError}
                notifierFunc={showToastNotification}
              />
            </PrivateRoute>
          </Route>
          <Route path="/usage-monitor">
            <PrivateRoute adminBlock={true}>
              <UsageMonitor
                globalErrorHandler={setLoadError}
                notifierFunc={showToastNotification}
              />
            </PrivateRoute>
          </Route>
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
