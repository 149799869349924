import { useKeycloak } from "@react-keycloak/web";
import LoadingBars from "../components/loading/LoadingBars";
import Unauthorized from "../components/unauthorized/Unauthorized";

interface PrivateRouteProps {
  children: JSX.Element;
  adminBlock: boolean;
}

const PrivateRoute = ({ children, adminBlock }: PrivateRouteProps) => {
  const { keycloak, initialized } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  const isAdminRole = (initialized &&
    keycloak.authenticated &&
    keycloak.hasRealmRole("admin")) as boolean;

  //keycloak.hasResourceRole("admin");

  if (initialized && !isLoggedIn) {
    keycloak.login();
  }

  if (adminBlock && !isAdminRole) {
    return isLoggedIn ? (
      <>
        <Unauthorized />
      </>
    ) : (
      <>
        <LoadingBars />
      </>
    );
  }

  return isLoggedIn ? (
    <div>{children}</div>
  ) : (
    <>
      <LoadingBars />
    </>
  );
};

export default PrivateRoute;
