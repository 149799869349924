import React from "react";
import { useHistory } from "react-router-dom";
import "./SubmissionSuccess.css";

interface SubmitSuccessProps {
  refNum: string;
  notifierFunc: (message: string, success: boolean) => void;
}

function SubmissionSuccess({ refNum, notifierFunc }: SubmitSuccessProps) {
  const history = useHistory();

  const navigateToHome = () => {
    history.push("./");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(refNum);
    notifierFunc("Ticket number copied to clipboard!", true);
  };

  return (
    <div>
      <p className="centerText w3-animate-bottom">
        <i className="fa fa-check-circle-o submitIconProps"></i>
      </p>
      <h2 className="centerText">
        Your ticket has been successfully Submitted
      </h2>
      <p className="centerText infoSpacing ticketNumProps">
        Below is your ticket number for reference. An email copy of your
        incident has also been sent to you.
      </p>
      <p className="centerText ticketNumProps">
        It may take up to 48hrs for a response. Please check this portal and/or
        your email for updates.
      </p>

      <div>
        <p className="centerText ticketNumProps">
          <span>
            <strong>Incident Number: </strong>
          </span>
          {refNum}
          <span>
            <i
              title="Copy to clipboard"
              className="fa fa-clipboard copyBtn"
              onClick={() => copyToClipboard()}
            ></i>
          </span>
        </p>
      </div>

      <p className="centerText infoSpacing">
        <i
          onClick={() => navigateToHome()}
          title="Return to landing"
          className="fa fa-home homeIconProps"
        ></i>
      </p>
    </div>
  );
}

export default SubmissionSuccess;
