import React from "react";
import * as TableStyles from "../../../../themes/TableTheme";
import * as Models from "../../../../models/GlobalModels";

interface Props {
  id: string;
  appName: string;
  uniqueIdentifier: string;
  lastAccessed: string;
  deletionAction: (record: Models.ModalSelectionData) => void;
}

function AppUsageRow({
  id,
  appName,
  uniqueIdentifier,
  lastAccessed,
  deletionAction,
}: Props) {
  return (
    <>
      <TableStyles.StyledTableRow hover tabIndex={-1}>
        <TableStyles.StyledTableCell align="center">
          {uniqueIdentifier}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {appName}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {lastAccessed}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          <p
            className="tableRowDeleteIcon"
            title="Delete Record?"
            onClick={() => deletionAction({ id: id, title: uniqueIdentifier })}
          >
            <i className="fa fa-trash-o" />
          </p>
        </TableStyles.StyledTableCell>
      </TableStyles.StyledTableRow>
    </>
  );
}

export default AppUsageRow;
