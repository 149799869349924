import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./GenericDropdown.css";

interface GenericDropdownProps {
  controlRenderType: "standard" | "outlined" | "filled" | undefined;
  controlName: string;
  controlTitle: string;
  selValue: string;
  formikChange: any;
  classes: string;
  formikBlur: any;
  isRequired: boolean;
  isDisabled: boolean;
  data: string[];
  touchedProps: boolean | undefined;
  errorProps: string | undefined;
  autoPickFirst: boolean;
}

function GenericDropdown({
  controlRenderType,
  controlName,
  controlTitle,
  selValue,
  formikChange,
  formikBlur,
  classes,
  isRequired,
  isDisabled,
  data,
  touchedProps,
  errorProps,
  autoPickFirst,
}: GenericDropdownProps) {
  const selVal = selValue !== undefined ? selValue : ("" as string);
  const selectedValue = (autoPickFirst ? data[0] : selVal) as string;
  return (
    <>
      <FormControl
        required={isRequired}
        className={classes}
        variant={controlRenderType}
      >
        <InputLabel>{controlTitle}</InputLabel>
        <Select
          name={controlName}
          value={selectedValue}
          onChange={formikChange}
          label={errorProps ? errorProps : controlTitle}
          onBlur={formikBlur}
          disabled={isDisabled}
          error={(errorProps ? true : false) && touchedProps}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {data?.map((item) => (
            <MenuItem key={Math.random()} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default GenericDropdown;
