/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, Form as FormikForm } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect } from "react";
import * as Models from "../../../models/GlobalModels";
import { Modal, Row, Col, Container } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { appNodeSchema } from "../../../validations/AppNodeSchema";
import useApi from "../../../api/useApi";
import { userObject } from "../../../store/UserContext";
import "./AppNodeModal.css";
import * as AppNodeDropdown from "../../../data/AppNodeTypesDropdown";
import LoadingBars from "../../../components/loading/LoadingBars";
import DeleteNodeModal from "./DeleteNodeModal";

interface AppNodeModalProps {
  notifierFunc: (message: string, success: boolean) => void;
  modalProps: Models.AppNodeModal;
  modalVisible: boolean;
  closeModalFunc: () => void;
  submitFunc: (message: string) => void;
  globalErrorHandler: any;
}

function AppNodeModal({
  notifierFunc,
  modalProps,
  modalVisible,
  closeModalFunc,
  submitFunc,
  globalErrorHandler,
}: AppNodeModalProps) {
  const axios = useApi();

  const [selectedNode, setSelectedNode] = React.useState<
    Models.UIAppNode | undefined
  >(undefined);

  const [userObj] = useRecoilState<Models.AntfIdentityModel>(userObject);

  const getModalTitle = (override: string) => {
    console.log("Override: ", override);
    if (modalProps.modalType === "edit" || override === "edit") {
      return "Edit App Node";
    } else if (modalProps.modalType === "view" && override === "") {
      return "About This App Node";
    } else if (modalProps.modalType === "delete" || override === "del") {
      return "Delete App Node";
    } else {
      return "Create New App Node";
    }
  };

  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [modalMode, setModalMode] = React.useState<string>("");
  const [modalTitle, setModalTitle] = React.useState<string>("");

  const appTypesList = AppNodeDropdown.appNodeTypes as string[];
  const appMessagesTypeList = AppNodeDropdown.appNodeMessages as string[];

  const isCreateMode = (modalProps.modalType === "new" ||
    modalProps.modalType === "edit") as boolean;

  const editCss =
    modalMode === "edit"
      ? "fa fa-pencil editIcon hideEdit"
      : ("fa fa-pencil editIcon" as string);

  const isViewMode = (modalProps.modalType === "view") as boolean;
  const editModalCloseCss =
    modalMode === "edit" ? "editCloseBtn" : ("" as string);
  const closeBtnCss = `floatRight ${editModalCloseCss}` as string;
  const modalFooterContainerCss =
    modalMode === "edit" || modalProps.modalType === "view"
      ? "modalFooterBtn"
      : ("modalFooterNewBtn" as string);

  const fetchAppNode = () => {
    if (modalProps.selId > 0) {
      const request = `/Apps/GetAppById/${modalProps.selId}` as string;
      setShowLoader(true);
      axios
        .get(request)
        .then((response) => {
          const result = response.data as Models.AppFetch;
          setShowLoader(false);
          setSelectedNode(result.node);
        })
        .catch((error: Error) => {
          setShowLoader(false);
          globalErrorHandler(() => {
            throw error;
          });
        });
    }
  };

  const editAppNode = (payload: Models.UIAppNode) => {
    axios
      .put("/Apps/UpdateApp", payload)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        setShowLoader(false);
        if (result.success) {
          submitFunc(result.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.message.includes("400")) {
          notifierFunc(error.response.data.message, false);
        } else {
          globalErrorHandler(() => {
            throw error;
          });
        }
      });
  };

  const deleteAppNode = (appKey: string | undefined) => {
    setShowLoader(true);
    if (appKey !== undefined) {
      const request = `/Apps/DeleteApp/${appKey}` as string;
      axios
        .delete(request)
        .then((response) => {
          const result = response.data as Models.GenericPost;
          setShowLoader(false);
          if (result.success) {
            submitFunc(result.message);
          }
        })
        .catch((error) => {
          setShowLoader(false);
          if (error.message.includes("400")) {
            notifierFunc(error.response.data.message, false);
          } else {
            globalErrorHandler(() => {
              throw error;
            });
          }
        });
    } else {
      setShowLoader(false);
      notifierFunc("Error: App Node Key Undefined", false);
    }
  };

  const setEditMode = () => {
    setModalTitle(getModalTitle("edit"));
    setModalMode("edit");
  };

  const setDeleteMode = () => {
    setModalTitle(getModalTitle("del"));
    setModalMode("del");
  };

  useEffect(() => {
    setModalTitle(getModalTitle(""));
    setModalMode("");
    if (modalProps.modalType === "new") {
      setSelectedNode(undefined);
    }

    if (modalProps.modalType === "view") {
      fetchAppNode();
    }
  }, [modalProps.modalId]);

  const createNewAppNode = (payload: Models.UIAppNode) => {
    axios
      .post("/Apps/CreateNewApp", payload)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        setShowLoader(false);
        if (result.success) {
          submitFunc(result.message);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        if (error.message.includes("400")) {
          notifierFunc(error.response.data.message, false);
        } else {
          globalErrorHandler(() => {
            throw error;
          });
        }
      });
  };

  const submitFormData = (payload: Models.UIAppNode) => {
    if (payload.message.length > 0 && payload.messageType.length === 0) {
      notifierFunc("Please choose a message type", false);
    } else {
      setShowLoader(true);
      if (modalProps.modalType === "new") {
        createNewAppNode(payload);
      } else if (modalMode === "edit") {
        editAppNode(payload);
      }
    }
  };

  return (
    <div>
      <Modal
        show={modalVisible}
        onHide={closeModalFunc}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <h2 className="antfColor">{modalTitle}</h2>
        </Modal.Header>
        {modalMode !== "del" ? (
          <Modal.Body>
            {!showLoader ? (
              <Formik
                enableReinitialize
                initialValues={{
                  id: selectedNode?.id || 0,
                  appKey: selectedNode?.appKey || "",
                  appName: selectedNode?.appName || "",
                  appType: selectedNode?.appType || "",
                  appOwner:
                    selectedNode?.appOwner ||
                    userObj?.keyCloakIdentity?.username,
                  created: selectedNode?.created || "",
                  createdBy: selectedNode?.createdBy || "",
                  modified: selectedNode?.modified || "",
                  message: selectedNode?.message || "",
                  messageType: selectedNode?.messageType || "",
                  modifiedBy: selectedNode?.modifiedBy || "",
                  isInactive: selectedNode?.isInactive || false,
                }}
                validationSchema={appNodeSchema}
                onSubmit={(values: any) => {
                  submitFormData(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  errors,
                  values,
                }) => {
                  const clearAppMesage = () => {
                    setFieldValue("message", "");
                    setFieldValue("messageType", "");
                  };

                  return (
                    <div>
                      <FormikForm>
                        <Row>
                          <Col xs={12}>
                            <TextField
                              name="appName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.appName}
                              className="controlWidth"
                              disabled={isViewMode && modalMode === ""}
                              error={
                                (errors.appName ? true : false) &&
                                touched.appName
                              }
                              required
                              label={
                                errors.appName ? errors.appName : "App Name"
                              }
                            />
                          </Col>
                        </Row>

                        {!isCreateMode ? (
                          <Row className="controlSpacingTop">
                            <Col xs={12}>
                              <TextField
                                name="appKey"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.appKey}
                                disabled={true}
                                className="controlWidth"
                                required
                                label="App API Key"
                              />
                            </Col>
                          </Row>
                        ) : (
                          <></>
                        )}

                        <Row className="controlSpacingTop">
                          <Col xs={6}>
                            <FormControl className="controlSpacingTop controlWidth">
                              <InputLabel required>
                                {errors.appType ? errors.appType : "App Type"}
                              </InputLabel>
                              <Select
                                name="appType"
                                value={values.appType}
                                onChange={handleChange}
                                disabled={isViewMode && modalMode === ""}
                                error={
                                  (errors.appType ? true : false) &&
                                  touched.appType
                                }
                                onBlur={handleBlur}
                              >
                                {appTypesList.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Col>

                          <Col xs={6}>
                            <TextField
                              name="appOwner"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.appOwner}
                              disabled={isViewMode && modalMode === ""}
                              className="controlWidth appOwnerSpacing"
                              error={
                                (errors.appOwner ? true : false) &&
                                touched.appOwner
                              }
                              required
                              label={
                                errors.appOwner ? errors.appOwner : "App Owner"
                              }
                            />
                          </Col>
                        </Row>

                        {!isCreateMode ? (
                          <>
                            <Row className="controlSpacingTop">
                              <Col xs={6}>
                                <TextField
                                  name="created"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.created}
                                  disabled={true}
                                  className="controlWidth"
                                  required
                                  label="Created Date"
                                />
                              </Col>

                              <Col xs={6}>
                                <TextField
                                  name="createdBy"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.createdBy}
                                  disabled={true}
                                  className="controlWidth"
                                  required
                                  label="Created By"
                                />
                              </Col>
                            </Row>

                            <Row className="controlSpacingTop">
                              <Col xs={6}>
                                <TextField
                                  name="modified"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.modified}
                                  disabled={true}
                                  className="controlWidth"
                                  required
                                  label="Modified Date"
                                />
                              </Col>

                              <Col xs={6}>
                                <TextField
                                  name="modifiedBy"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.modifiedBy}
                                  disabled={true}
                                  className="controlWidth"
                                  required
                                  label="Modified By"
                                />
                              </Col>
                            </Row>

                            <Row className="controlSpacingTop">
                              <Col xs={9}>
                                <TextField
                                  name="message"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.message}
                                  className="controlWidth"
                                  disabled={isViewMode && modalMode === ""}
                                  label="App Message (Optional) (Shows on Other Apps!)"
                                />
                              </Col>

                              <Col xs={3}>
                                <FormControl className="controlWidth">
                                  <InputLabel required>
                                    {errors.appType
                                      ? errors.appType
                                      : "Message Type"}
                                  </InputLabel>
                                  <Select
                                    name="messageType"
                                    value={values.messageType}
                                    onChange={handleChange}
                                    disabled={isViewMode && modalMode === ""}
                                    error={
                                      (errors.appType ? true : false) &&
                                      touched.appType
                                    }
                                    onBlur={handleBlur}
                                  >
                                    {appMessagesTypeList.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Col>
                            </Row>
                            {modalMode === "edit" ? (
                              <Row>
                                <p
                                  className="textLinkProps"
                                  onClick={() => clearAppMesage()}
                                >
                                  Clear App Message
                                </p>
                              </Row>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {!isCreateMode ? (
                          <FormControlLabel
                            className="inactiveCheckProps"
                            control={
                              <Checkbox
                                name="isInactive"
                                checked={values.isInactive}
                                required
                                disabled={isViewMode && modalMode === ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            }
                            label="Deactivate/Suspend"
                          />
                        ) : (
                          <div className="noInactiveSpacing"></div>
                        )}
                      </FormikForm>

                      <Modal.Footer className={modalFooterContainerCss}>
                        <Container fluid>
                          {isViewMode ? (
                            <div className="mr-auto">
                              <span>
                                <i
                                  title="Edit This App Node"
                                  className={editCss}
                                  onClick={() => setEditMode()}
                                />
                              </span>
                              <span>
                                <i
                                  title="Delete This App Node"
                                  className="fa fa-trash deleteIcon"
                                  onClick={() => setDeleteMode()}
                                />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="modalFooterBtn">
                            {!isViewMode || modalMode === "edit" ? (
                              <Button
                                className="submitBtn floatRight finishBtnProps"
                                onClick={() => handleSubmit()}
                                variant="contained"
                                color="primary"
                              >
                                Finish
                              </Button>
                            ) : (
                              <></>
                            )}

                            <Button
                              onClick={() => closeModalFunc()}
                              variant="contained"
                              color="secondary"
                              className={closeBtnCss}
                            >
                              Close
                            </Button>
                          </div>
                        </Container>
                      </Modal.Footer>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <div className="modalLoadinBarPos">
                <LoadingBars />
              </div>
            )}
          </Modal.Body>
        ) : (
          <>
            <DeleteNodeModal
              deleteFunc={deleteAppNode}
              appNodeKey={selectedNode?.appKey}
              closeModalFunc={closeModalFunc}
            />
          </>
        )}
      </Modal>
    </div>
  );
}

export default AppNodeModal;
