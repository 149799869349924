import React from "react";
import ErrorPage from "../error/Error";

interface MyProps {
  children: any;
}

interface MyState {
  error: any;
  eventId: any;
}

class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
    //const eventId = ErrorLogger.logError(error, errorInfo, "System");
    const eventId = "Test123";
    this.setState({ eventId });
  }

  render() {
    const { error, eventId } = this.state;
    const { children } = this.props;

    // Render fallback UI
    if (error) {
      return <ErrorPage statusMessage={error.message} eventID={eventId} />;
    }
    // When there's not an error, render the children untouched
    return children;
  }
}

export default ErrorBoundary;
