import React from "react";
import * as TableStyles from "../../../../themes/TableTheme";
import * as DataUtils from "../../../../utils/DataConversionUtils";
import "./EventLogRow.css";

interface EventLogRowProps {
  eventId: string;
  errorMsg: string;
  appName: string;
  framework: string;
  created: string;
  status: boolean;
  eventSelectionFunc: (eventID: string) => void;
}

function EventLogRow({
  eventId,
  errorMsg,
  appName,
  framework,
  created,
  status,
  eventSelectionFunc,
}: EventLogRowProps) {
  return (
    <>
      <TableStyles.StyledTableRow hover tabIndex={-1}>
        <TableStyles.StyledTableCell
          component="th"
          align="center"
          scope="row"
          onClick={() => eventSelectionFunc(eventId)}
        >
          <span className="idField">
            {DataUtils.ConvertTextToShorthand(errorMsg, 50)}
          </span>
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {appName}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {framework}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {created}
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          {status ? (
            <i
              className="fa fa-check-circle-o eventStatusIcon antfSuccessColor"
              title="Issue is Resolved"
            ></i>
          ) : (
            <i
              className="fa fa-exclamation-triangle eventStatusIcon antfWarnColor"
              title="Issue is Unresolved"
            ></i>
          )}
        </TableStyles.StyledTableCell>
      </TableStyles.StyledTableRow>
    </>
  );
}

export default EventLogRow;
