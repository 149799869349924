export const incidentIssues = [
  "Application Issues",
  "Installation Issues",
  "Login or Security Issues",
  "Documentation Issues",
  "ANTF or CS90 Site Connection Issues",
  "General Inquiry",
  "Other",
] as string[];

export const incidentSeverity = [
  "Low Impact",
  "Medium Impact (Normal)",
  "High Impact",
  "Critical Impact",
] as string[];
