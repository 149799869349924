import React from "react";
import "./IncDetails.css";

interface IncDetailsProps {
  desc: string;
  notes: string;
}

function IncDetails({ desc, notes }: IncDetailsProps) {
  return (
    <>
      <h5 className="headerPadding antfColor">Incident Details</h5>
      <p>
        <span className="boldParagraph antfSecondaryColor">Description: </span>
        {desc}
      </p>
      <p>
        <span className="boldParagraph antfSecondaryColor">Notes: </span>
        {notes}
      </p>
      <hr />
    </>
  );
}

export default IncDetails;
