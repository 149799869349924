import { Button } from "@material-ui/core";
import React from "react";
import * as Models from "../../../models/GlobalModels";
import * as DataUtils from "../../../utils/DataConversionUtils";
import useApi from "../../../api/useApi";
import { Modal, Container, Row } from "react-bootstrap";

interface Props {
  showFunc: boolean;
  hideFunc: React.Dispatch<React.SetStateAction<boolean>>;
  data?: Models.ModalSelectionData;
  globalErrorHandler: any;
  onPostSuccessFunc: () => void;
  notifierFunc: (message: string, success: boolean) => void;
}

function UsageDeletionModal({
  showFunc,
  hideFunc,
  data,
  globalErrorHandler,
  onPostSuccessFunc,
  notifierFunc,
}: Props) {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const titleText = DataUtils.IsNullOrEmpty(data?.title)
    ? "Unknown Record"
    : (data?.title as string);
  const axios = useApi();

  const deleteRecord = (recordId?: string) => {
    if (!DataUtils.IsNullOrEmpty(recordId)) {
      axios
        .delete(`/Usages/DeleteUsageEvent/${recordId}`)
        .then((response) => {
          const result = response.data as Models.GenericPost;
          if (result.success) {
            onPostSuccessFunc();
            closeModal();
          }
        })
        .catch((error: Error) => {
          globalErrorHandler(() => {
            throw error;
          });
        });
    } else {
      notifierFunc("Failed to delete app usage record!", false);
    }
  };

  const closeModal = () => {
    setHasError(false);
    hideFunc(false);
  };

  return (
    <div>
      <Modal
        show={showFunc}
        onHide={() => hideFunc(false)}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <h2 className="antfMaterialSecondary ">
            <i className="fa fa-trash-o noteModalTitleIcon"></i>Delete App Usage
            Record?
          </h2>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <strong className="centerText">
                Are you sure You want to delete this app usage record? This
                cannot be undone?
              </strong>
              <h1 className="centerText modalActionIcon antfMaterialSecondary ">
                <i className="fa fa-times-circle-o"></i>
              </h1>
              <strong className="centerText">Record: {titleText}</strong>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModal()}
            variant="contained"
            color="primary"
            className="noteModalFooterBtnSpacing"
          >
            Close
          </Button>
          <Button
            className="submitBtn"
            variant="contained"
            color="secondary"
            onClick={() => deleteRecord(data?.id)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UsageDeletionModal;
