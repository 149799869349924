import React from "react";
import { Container, Row } from "react-bootstrap";
import NoAccessImg from "../../assets/NoAccess.png";
import "./Unauthorized.css";

function Unauthorized() {
  return (
    <>
      <Container className="unauthDataContainer" fluid>
        <Row>
          <img
            className="unauthImgSize unauthImgProps"
            src={NoAccessImg}
            alt="No Access"
          />
        </Row>
      </Container>
    </>
  );
}

export default Unauthorized;
