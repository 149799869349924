import { Button } from "@mui/material";
import * as ImageUtilities from "../../utils/ImgUtilities";
import React from "react";
import "./UploadControl.css";

interface UploadControlProps {
  imageReturnFunc: (imgData: File) => Promise<void>;
  notifier: (message: string, success: boolean) => void;
  cancelUploadFunc: () => void;
  selectedFilename: string;
}

function UploadControl({
  imageReturnFunc,
  notifier,
  cancelUploadFunc,
  selectedFilename,
}: UploadControlProps) {
  const onImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const foundFile = e.target.files?.[0] as File;
    if (foundFile !== null && foundFile !== undefined) {
      const validationResult = ImageUtilities.validateFile(
        foundFile,
        3000000
      ) as string;
      if (validationResult.length === 0) {
        imageReturnFunc(foundFile);
      } else {
        notifier(validationResult, false);
      }
    }
  };

  const fileTitle = (
    selectedFilename !== "" ? selectedFilename : "No File Selected"
  ) as string;

  return (
    <div className="appContainer">
      <div>
        <Button
          className="uploadTitle"
          variant="contained"
          component="label"
          color="success"
        >
          <span>
            <i id="attachmentUploader" className="fa fa-paperclip"></i> Attach
            Screenshot (Optional)
          </span>
          <input onChange={(e) => onImageUpload(e)} type="file" hidden />
        </Button>
        <span>
          <p className="uploadTitle uploadTitleProps">{fileTitle}</p>
          {fileTitle !== "No File Selected" ? (
            <i
              onClick={() => cancelUploadFunc()}
              title="Cancel upload of this file"
              className="fa fa-times-circle-o uploadTitleProps cancelIconProps"
            ></i>
          ) : (
            <></>
          )}
        </span>
      </div>
    </div>
  );
}

export default UploadControl;
