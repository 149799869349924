import { TextField, Button } from "@material-ui/core";
import React from "react";
import * as Models from "../../../models/GlobalModels";
import useApi from "../../../api/useApi";
import { Modal, Container, Row } from "react-bootstrap";
import "./NewNoteModal.css";

interface NewNoteModalProps {
  showFunc: boolean;
  hideFunc: React.Dispatch<React.SetStateAction<boolean>>;
  ticketNum: string;
  globalErrorHandler: any;
  onPostSuccessFunc: () => void;
}

function NewNoteModal({
  showFunc,
  hideFunc,
  ticketNum,
  globalErrorHandler,
  onPostSuccessFunc,
}: NewNoteModalProps) {
  const [noteData, setNoteData] = React.useState<string>("");
  const [hasError, setHasError] = React.useState<boolean>(false);
  const axios = useApi();

  const bodyText = hasError
    ? "Validation Error: Note cannot be blank"
    : ("Your note/comment goes here" as string);

  const handleSubmit = () => {
    if (noteData.length > 0) {
      const noteObj = {
        ticketID: ticketNum,
        note: noteData,
      } as Models.UIWorkNote;
      postWorkNoteToTicket(noteObj);
    } else {
      setHasError(true);
    }
  };

  const postWorkNoteToTicket = (noteObj: Models.UIWorkNote) => {
    axios
      .post("/Notes/CreateNote", noteObj)
      .then((response) => {
        const result = response.data as Models.GenericPost;
        if (result.success) {
          onPostSuccessFunc();
          closeModal();
        }
      })
      .catch((error: Error) => {
        globalErrorHandler(() => {
          throw error;
        });
      });
  };

  const handleNoteTextChange = (textData: string) => {
    if (textData.length > 0 || noteData.length > 0) {
      setHasError(false);
    }
    setNoteData(textData);
  };

  const closeModal = () => {
    setNoteData("");
    setHasError(false);
    hideFunc(false);
  };

  return (
    <div>
      <Modal
        show={showFunc}
        onHide={() => hideFunc(false)}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <h2 className="antfColor">
            <i className="fa fa-comments-o noteModalTitleIcon"></i>Create New
            Note
          </h2>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <TextField
                id="outlined-multiline-flexible"
                name="noteText"
                onChange={(e) => handleNoteTextChange(e.target.value)}
                value={noteData}
                required
                multiline
                error={hasError}
                label={bodyText}
                rows={10}
                variant="outlined"
              />
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeModal()}
            variant="contained"
            color="secondary"
            className="noteModalFooterBtnSpacing"
          >
            Close
          </Button>
          <Button
            className="submitBtn"
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewNoteModal;
