export function GetTicketSeverityColor(severity: string): string {
  if (severity.includes("Critical")) {
    return "criticalColor";
  } else if (severity.includes("High")) {
    return "highColor";
  } else if (severity.includes("Medium")) {
    return "medColor";
  } else {
    return "lowColor";
  }
}
